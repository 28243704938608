import {INIT_COUNTER, UPDATE_COUNTER, UPDATE_LIST} from "./types";
import {TIMER_TICK} from "./types";
import {produce} from "immer";
import {getCurrentDateTime} from "../../../utils";


const initialState = {
    counted: 0,
    countList: [
        {
            startDateTime: 1,
            sum: 0
        }
    ],
    startDateTime: 0,
    fetchedData: false,
    extraTickCounts: 0,
    lastCountDateTime: 0
};

export default function counterReducer(state = initialState, action) {
    switch (action.type) {
        case INIT_COUNTER: {
            break;
        }

        case UPDATE_LIST: {
            return produce(state, (draft) => {
                draft.countList = action.payload.countList.sort(
                    (a, b) => {
                        return (b.startDateTime - a.startDateTime);
                    }).reverse();
                draft.fetchedData = true;
            });
        }

        case UPDATE_COUNTER: {
            return produce(state, (draft) => {
                draft.counted = action.payload;
            });
        }

        case TIMER_TICK: {
            return produce(state, (draft) => {

                const found_count = state.countList.find((count) => {
                    return (count.startDateTime > getCurrentDateTime());
                });


                if ((found_count !== undefined) && state.countList.length > 0) {
                    if (state.counted === found_count.sum) {
                        // tick here
                        /*draft.extraTickCounts = (Date.now() - state.lastCountDateTime) / 100000  ; */
                    } else {
                        draft.counted = found_count.sum;
                        draft.lastCountDateTime = found_count.startDateTime
                    }
                }
            });

        }

        default:
            return state;
    }
}
