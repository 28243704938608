/* OPERATIONS = REDUX THUNKS
This file defines the public interface of the duck -- what can be dispatched from components
Simple operations are just about forwarding an action creator, ex: simpleQuack
Complex operations involve returning a thunk that dispatches multiple actions in a certain order, ex: complexQuack
*/

import {SET_ACTIVE_ALBUM} from "./types";
import {SET_ACTIVE_TRACKLIST} from "./types";
import {SET_MODAL_ACTIVE} from "./types";
import {SET_MODAL_COVERART} from "./types";


export function setActiveAlbum(displayAlbum) {
    if (displayAlbum !== undefined) {
        return (dispatch) => {
            dispatch({
                type: SET_ACTIVE_ALBUM,
                payload: {displayAlbum: displayAlbum}
            });
        };
    }
}

export function setActiveTracklist(tracklist) {
    if (tracklist !== undefined) {
        return (dispatch) => {
            dispatch({
                type: SET_ACTIVE_TRACKLIST,
                payload: {activeTrackList: tracklist}
            });
        };
    }
}

export function setModalActive(modalActive) {
    if (modalActive !== undefined) {
        return (dispatch) => {
            dispatch({
                type: SET_MODAL_ACTIVE,
                payload: {modalActive: modalActive}
            });
        };
    }
}

export function setModalCoverArtSrc(album) {
    return (dispatch) => {
        dispatch({
            type: SET_MODAL_COVERART,
            payload: {
                modalCoverArtSrc: album.coverArtSrc,
                modalCoverArtHighresSrc: album.coverArtHighresSrc,
            }
        });

    };
}

export {};
