import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Text from "./Text";
import Button from "react-bootstrap/Button";
import XIcon from "./Icons/XIcon";
import React from "react";
import {useDispatch} from "react-redux";
import {setActivePage_exitCurrent} from "../../state/ducks/showPage/operations";
import {Link} from "react-router-dom";


export const ViewHeader = ({title}) => {
    const dispatch = useDispatch();

    const exit = () => {
        dispatch(setActivePage_exitCurrent());
    };
    return (<Row>
        <Col xs={10} className='my-auto'>
            <Text style={{fontSize: "2.25em"}}>{title}</Text>
        </Col>
        <Col xs={2} className='text-right'><Text style={{fontSize: "1.5em"}} className='text-right'>

            <Link to={"/"}>

            <Button size='sm' variant='link'>
                <Text className='close-x' ><XIcon/></Text>
            </Button>
            </Link>
        </Text></Col>

    </Row>);
};
