import {Fragment} from "react";
import React from "react";
import FadeIn from "./FadeIn";
import Text from "./Text";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";


export const TrackList = ({onMouseOverFn, tracklist = [], containerClassName, className = "album-cover-text"}) => {

    return (
        <Fragment>
            {
                tracklist.map((track, order) => {
                    let _containerClassName = containerClassName.toString();
                    _containerClassName += track.ltr ? " text-right" : "";
                    _containerClassName += track.coverArtSrc ? " tracklist-hover" : " tracklist-nohover";

                    const colWidth = track.timecode ? 10 : 12;

                    return (<FadeIn key={track.name + order} order={order} fadeTime={500}>
                            <Row style={{margin: 0}}>
                                {track.timecode ?
                                    <Col xs={2} style={{
                                        padding: 0,
                                        margin: 0
                                    }}>
                                        <div className={'modal-tracklist-track-container tracklist-nohover'} style={{padding: "0", paddingTop: "0", marginBottom: "0.3vw"}}><Text
                                            className={className}>{track.timecode}</Text>
                                        </div>
                                    </Col>
                                    : ""}


                                <Col xs={colWidth} style={{
                                    padding: 0,
                                    margin: 0
                                }}>
                                    <div key={track.name + order} onMouseOver={() => {
                                        onMouseOverFn(track);
                                    }}
                                         style={{padding: "0", paddingTop: "0", marginBottom: "0.3vw"}}
                                         className={_containerClassName}>

                                        <Text onMouseOver={() => {
                                            onMouseOverFn(track);
                                        }} className={className}
                                              style={{margin: 0}}>
                                            {track.name}
                                        </Text>

                                    </div>
                                </Col>
                            </Row>
                        </FadeIn>
                    );
                })
            }
        </Fragment>
    );
};
