import React from "react";


const GlobeIcon = ({...rest}) => (
    <svg width="1em" height="1em" viewBox="0 0 420 420" className="bi bi-globe" stroke="currentColor" {...rest}
         xmlns="http://www.w3.org/2000/svg">
        <path strokeWidth="26"
              d="M209,15a195,195 0 1,0 2,0z"/>
        <path strokeWidth="16"
              d="m210,15v390m195-195H15M59,90a260,260 0 0,0 302,0 m0,240 a260,260 0 0,0-302,0M195,20a250,250 0 0,0 0,382 m30,0 a250,250 0 0,0 0-382"/>
    </svg>
);

export default GlobeIcon;
