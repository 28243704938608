import Button from "react-bootstrap/Button";
import Text from "./Text";
import i18n from "../i18n";
import React from "react";
import {Link} from "react-router-dom";


export function CookieTipsButtons({setShowCookieModal}) {


    return <div style={{paddingTop: "2vw"}}>
        <Link to={"/privacy"}>
            <Button size="sm" variant="link"><Text className="footer-size "
                                                   style={{opacity: "70%"}}>
                {i18n.t("privacy.title")}
            </Text>
            </Button>
        </Link>
        <Text className="footer-size "
              style={{opacity: "70%"}}>
            |
        </Text>

        <Button as="a" size="sm" variant="link" href={"https://hosst.no"} target="_blank">
            <Text className="footer-size "
                  style={{opacity: "70%"}}>
                {i18n.t("madebyrebell.label")}
            </Text>
        </Button>


    </div>;
}
