import React from "react";
import {Fragment} from "react";
import {connect} from "react-redux";
import {start, stop} from "../../state/ducks/counter/operations";
import Text from "./Text";
import Reel from "react-reel";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import i18n from "../i18n";
import {GrantsOneLinerForCounter} from "./Grants";
import {GRANTS} from "../constants";
import ColoredLine from "./ColoredLine";
//import Reel from "./Reel";


const reelTheme = {
    reel: {
        height: "1em",
        display: "flex",
        alignItems: "flex-end",
        overflowY: "hidden",
        lineHeight: "0.95em",
        marginLeft: "0.2em"
    },
    group: {
        transitionDelay: "0ms",
        transitionTimingFunction: "ease-in-out",
        transform: "translate(0, 0)",
        height: "1em"
    },
    number: {
        height: "1em",
        minWidth: "0.3em"
    },
};


const ReelCounter = ({countTo}) => {

    const decimal = new Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: "NOK",
    }).format(countTo).replace(".", "‎").replace(".", "‎");

    return (
        <Fragment>

            <Reel theme={reelTheme}
                  text={decimal}
            />
        </Fragment>
    );

    /*return (
        <NumberFormat decimalScale={2} value={countTo} displayType={"text"}
                      thousandSeparator={" "}
                      decimalSeparator={","}/>
    );*/
};


class Counter extends React.Component {
    constructor(props) {
        super(props);
        this.props.start();
    }

    componentWillUnmount() {
        this.props.stop();
    }

    render() {

        return (<>
                <Row className="counter-row">
                    <Col xs={10} lg={"auto"} className='counter-col'>
                        <Text style={{textAlign: "center", marginLeft: 'right'}} className="counter-text">

                            {this.props.counter.counted + this.props.counter.extraTickCounts !== 0 &&
                            <ReelCounter countTo={this.props.counter.counted + this.props.counter.extraTickCounts}/>}

                            {this.props.counter.counted + this.props.counter.extraTickCounts === 0 &&
                            <span style={{fontSize: '0.5em'}}>{i18n.t("counter.error")}</span>}

                        </Text>
                    </Col>
                    <Col xs={12} lg={"auto"} style={{width: 'auto'}} className="counter-help-text-container">
                        <div style={{width: '100%'}}>
                        <Text className={"counter-help-text"}>{i18n.t("counter.label")}</Text><GrantsOneLinerForCounter />
                        </div>

                    </Col>
                </Row>

            </>
        );

    }
}


const mapDispatchToProps = dispatch => {
    return {
        start: () => {
            dispatch(start());
        },
        stop: () => {
            dispatch(stop());
        },
    };
};

const mapStateToProps = state => ({
    ...state
});

export default connect(mapStateToProps, mapDispatchToProps)(Counter);

