import {COLORS} from "../constants";
import React from "react";


const Text = ({children, fontSize, style, ...rest}) => {
    return (
        <span style={{ fontSize: fontSize, fontFamily: "bologna", color: COLORS.text, ...style}} {...rest}>
            {children}
        </span>

    );
};

export default Text;
