import FadeIn from "./FadeIn";
import {ViewHeader} from "./ViewHeader";
import i18n from "../i18n";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {GRANTS} from "../constants";
import Text from "./Text";
import React from "react";


const Contributors = () => {
    return (
        <div style={{marginTop: "1em"}} className="grants-container">

            <FadeIn fadeTime={600} order={0}>
                <ViewHeader title={i18n.t("contributors.title")}/>
            </FadeIn>

            <Row>
                <Col xs={12} sm={12} className="grants-body-container"
                     style={{paddingLeft: "", paddingRight: "1.5vw", paddingTop: "2vw", paddingBottom: "2vw",}}>
                    <FadeIn fadeTime={600} order={1}>
                        <div className="contributors" style={{width: "100%", position: "relative", whiteSpace: "pre-wrap"}}>
                            <Text>{i18n.t("contributors.text")}
                                <br />
                                <ul className="contributors-list">
                                    {i18n.t("contributors.list", {returnObjects: true}).map(contributor => <li>{contributor.name}</li>)}
                                </ul>
                            </Text>

                        </div>
                    </FadeIn>
                </Col>
            </Row>


        </div>
    );
};

export default Contributors;
