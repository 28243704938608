import React from "react";

import FadeIn from "./FadeIn";
import {ViewHeader} from "./ViewHeader";
import i18n from "../i18n";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Text from "./Text";
import {GRANTS} from "../constants";
import {useMediaQuery} from "react-responsive";


export const GrantsOneLinerForCounter = () => {
    const isMobile = useMediaQuery({query: "(max-width: 768px)"});

    return (

        <Text style={{paddingTop: "0.5em", textAlign: "center"}} className={"counter-help-text"}><span style={{
            fontWeight: "500",
            letterSpacing: "0.1em",
            fontSize: ""
        }}>, {GRANTS.map(g => g.sum).reduce((a, b) => a + b).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} NOK</span> {i18n.t("grants.counterText")}
        </Text>
    );
};

export const Grants = () => {
    const isMobile = useMediaQuery({query: "(max-width: 768px)"});


    return (

        <div className="grants-container">

            <FadeIn fadeTime={400} order={0}>
                <ViewHeader title={i18n.t("grants.title")}/>
            </FadeIn>
            <Row>
                <Col xs={12} sm={12} className="grants-body-container"
                     style={{paddingLeft: "", paddingRight: "1.5vw", paddingTop: "2vw", paddingBottom: "2vw",}}>
                    <FadeIn fadeTime={400} order={1}>
                        <div style={{width: "100%", position: "relative"}}>
                            {GRANTS.map((grant, i) => {
                                return (
                                    <FadeIn key={grant.grantee} fadeTime={400} order={1+i}>
                                        <div key={grant.grantee} className="grants-grid-wrapper" style={{}}>

                                            <div className="grants-headline-container">
                                                <Text className="grants-headline">{grant.grantee}</Text>
                                                <Text className="grants-date" style={{fontSize: "1.2em"}}>
                                                    {isMobile && <br/>}
                                                    {grant.date}
                                                </Text>
                                            </div>

                                            <div className="grants-sum-container">
                                                <Text className="grants-sum"
                                                      style={{textAlign: "right"}}> {grant.sum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
                                                </Text>
                                            </div>

                                            <div className="grants-reason">
                                                <Text style={{
                                                    fontSize: "1.2em",
                                                    lineSpacing: "0.5em"
                                                }}>{i18n.language === "no" ? grant.reason.no : grant.reason.en}</Text>
                                            </div>

                                            <div style={{textAlign: "", paddingBottom: "3em", paddingLeft: "1.5em"}}>
                                                <Text>
                                                    {!!grant.href && <a rel="noopener noreferrer" target="_blank" className="link"
                                                                        href={grant.href}>{i18n.t("grants.goToWebpage")}</a>}
                                                </Text>
                                            </div>

                                        </div>
                                    </FadeIn>

                                );
                            })}
                        </div>
                    </FadeIn>
                </Col>
            </Row>


        </div>

    );
};

export default Grants;
