import FadeIn from "./FadeIn";
import {ViewHeader} from "./ViewHeader";
import i18n from "../i18n";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {GRANTS} from "../constants";
import Text from "./Text";
import React from "react";


const CookiesAndPrivacy = () => {
    return (
        <div style={{marginTop: "-1em"}} className="grants-container">

            <FadeIn fadeTime={1000} order={0}>
                <ViewHeader title={i18n.t("privacy.title")}/>
            </FadeIn>
            <Row>
                <Col xs={12} sm={12} className="grants-body-container"
                     style={{paddingLeft: "", paddingRight: "1.5vw", paddingTop: "2vw", paddingBottom: "2vw",}}>
                    <FadeIn fadeTime={400} order={1}>
                        <div style={{width: "100%", position: "relative", whiteSpace: 'pre-wrap'}}>
                            <Text>{i18n.t("privacy.text")}</Text>
                        </div>
                    </FadeIn>
                </Col>
            </Row>
            <FadeIn fadeTime={400} order={2}>
                <ViewHeader title={i18n.t("cookies.title")}/>
            </FadeIn>
            <Row>
                <Col xs={12} sm={12} className="grants-body-container"
                     style={{paddingLeft: "", paddingRight: "1.5vw", paddingTop: "2vw", paddingBottom: "2vw",}}>
                    <FadeIn fadeTime={1000} order={1}>
                        <div style={{width: "100%", position: "relative", whiteSpace: 'pre-wrap'}}>
                            <Text>{i18n.t("cookies.text")}</Text>
                        </div>
                    </FadeIn>
                </Col>
            </Row>


        </div>
    );
};

export default CookiesAndPrivacy;
