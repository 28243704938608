import {SEND_TIPS} from "./types";
import {produce} from "immer";


const initialState = {
    name: "",
    telephoneNumber: "",
    email: "",
    message: ""
};

export default function contactFormReducer(state = initialState, action) {
    switch (action.type) {
        case SEND_TIPS: {
            return produce(state, (draft) => {
                draft.name = action.payload.name;
                draft.telephoneNumber = action.payload.telephoneNumber;
                draft.email = action.payload.email;
                draft.message = action.payload.message;

                // sendTheThingYouIdiot
            });
        }
        default:
            return state;
    }
}
