import React from "react";
import {useState} from "react";
import "./App.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Counter from "./components/Counter";
import ColoredLine from "./components/ColoredLine";
import LogoAndLanguagePicker from "./components/LogoAndLanguagePicker";
import i18n from "./i18n";
import Modal from "./components/Modal";
import FadeIn from "./components/FadeIn";
import {Contract} from "./components/Contract";
import {CookieTipsButtons} from "./components/CookieTipsFAQButtons";
import {AlmostARealRouter} from "./components/AlmostARealRouter";
import Button from "react-bootstrap/Button";
import Text from "./components/Text";
import {Fragment} from "react";
import XIcon from "./components/Icons/XIcon";
import {useRef} from "react";
import {BrowserRouter} from "react-router-dom";
import {Link} from "react-router-dom";


const ltr = i18n.dir() === "ltr";


const MobileMenu = () => {

    return (
        <Fragment>
            <ColoredLine className="d-block d-sm-none"/>
            <Row>
                <Col xs={6} className="d-block d-sm-none">
                    <Link to={"/faq"}>
                        <Button size="sm" variant={"link"} className="language-picker"
                        >
                            <Text className=" language-picker-text"
                                  style={{textTransform: "uppercase", fontSize: "1.2em", whiteSpace: "pre-wrap"}}>
                                {i18n.t("faq.label")}
                            </Text>
                        </Button>
                    </Link>
                    <Link to={"/contributors"}>
                        <Button size="sm" variant={"link"} className="language-picker ">
                            <Text className=" language-picker-text"
                                  style={{textTransform: "uppercase", fontSize: "1.2em"}}>
                                {i18n.t("contributors.title")}
                            </Text>
                        </Button>
                    </Link>
                </Col>
                <Col xs={6} className="d-block d-sm-none">
                    <Link to={"/contact"}>
                        <Button size="sm" variant={"link"} className="language-picker ">
                            <Text className=" language-picker-text"
                                  style={{textTransform: "uppercase", fontSize: "1.2em"}}>
                                {i18n.t("tips.label")}
                            </Text>
                        </Button>
                    </Link>
                    <Link to={"/grants"}>
                        <Button size="sm" variant={"link"} className="language-picker"

                        >
                            <Text className=" language-picker-text"
                                  style={{textTransform: "uppercase", fontSize: "1.2em"}}>
                                {i18n.t("grants.label")}
                            </Text>
                        </Button>
                    </Link>
                    {/*<a target="_blank" href={"https://spleis.no/paf"}>
                        <Button size="sm" variant={"link"} className="language-picker"

                        >
                            <Text className=" language-picker-text"
                                  style={{textTransform: "uppercase", fontSize: "1.2em"}}>
                                SPLEIS
                            </Text>
                        </Button>
                    </a>*/}
                </Col>
            </Row>
            <ColoredLine className="d-block d-sm-none"/>
        </Fragment>
    );
};

function App() {
    const [showCookieModal, setShowCookieModal] = useState(false);
    const [showFAQ, setShowFAQ] = useState(false);
    const fadeTime = 700; //ms

    const contentRef = useRef(null);

    return (
        <BrowserRouter>
            <div className="App">
                <Container fluid style={{
                    paddingLeft: "4vw",
                    paddingRight: "4vw",
                }}>

                    <Row className="justify-content-md-center d-md-block"
                         style={{heigth: "100%", paddingTop: "5vw", paddingBottom: "5vw"}}>

                        <Col
                            xs={{size: 12, order: 2}} md={{size: 5, order: "first"}}
                            className="float-md-left col-12 col-md-5"
                        >
                            <FadeIn order={4} fadeTime={fadeTime}>
                                <Contract contentRef={contentRef}/>
                            </FadeIn>

                            <FadeIn className="d-none d-sm-block" style={{paddingTop: "3vw"}} order={9}
                                    fadeTime={fadeTime}>
                                <CookieTipsButtons showFAQ={showFAQ} setShowFAQ={setShowFAQ}
                                                   setShowCookieModal={setShowCookieModal}/>
                            </FadeIn>

                        </Col>

                        <Col xs={{size: 12, order: "first"}} md={7} className="float-md-right">
                            <FadeIn order={1} fadeTime={fadeTime}>
                                <LogoAndLanguagePicker/>
                                <ColoredLine/>
                            </FadeIn>
                        </Col>

                        <Col
                            xs={{size: 12, order: 1}}
                            md={7}
                            className="float-md-right text-left col-12 col-md-7 mb-md-auto counter-text-holder"
                            style={{
                                paddingTop: "1vw",
                                paddingBottom: 0,
                                zIndex: -1000,
                                display: "block"
                            }}
                        >
                            <FadeIn travel={10} order={9} fadeTime={fadeTime}>
                                <Counter fadeTime={fadeTime}/>
                            </FadeIn>

                            <FadeIn order={4} fadeTime={fadeTime}>
                                <ColoredLine className="line-under-counter"/>
                            </FadeIn>
                        </Col>

                        <Col
                            xs={{size: 12, order: 3}}
                            md={7}
                            ref={contentRef}
                            className="float-md-right text-left mt-md-auto catalog-col"
                        >
                            <MobileMenu/>

                            <FadeIn order={4} fadeTime={fadeTime}>
                                <AlmostARealRouter/>
                            </FadeIn>
                        </Col>
                    </Row>
                    <Row className="footer-row" style={{marginBottom: "20vw"}}>
                        <Col xs={12}>
                            <FadeIn className="d-block d-sm-none" style={{paddingTop: "3vw"}} order={5}
                                    fadeTime={fadeTime}>
                                <CookieTipsButtons setShowFAQ={setShowFAQ} setShowCookieModal={setShowCookieModal}/>
                            </FadeIn>

                            <Modal showHeader={false} showFooter={false} dialogClassName="modal-90w"
                                   rtl={ltr} closeFn={() => {
                                setShowCookieModal(false);
                            }} show={showCookieModal} title={i18n.t("cookies.title")}>

                                {/*<Image  style={{width: "100%", cursor: "pointer"}} src={contractSrcUrl}/>*/}

                                <div><Text style={{fontSize: "2.25em"}}>{i18n.t("cookies.title")}</Text></div>

                                {i18n.t("privacy.text")}

                                <div style={{float: "right", marginTop: "5em"}}>

                                    <Button size="lg" variant="link" onClick={() => {
                                        setShowCookieModal(false);
                                    }}>
                                        <Text className="close-x"><XIcon/></Text>
                                    </Button>

                                </div>
                            </Modal>
                        </Col>
                    </Row>

                </Container>


            </div>
        </BrowserRouter>
    );
}

export default App;
