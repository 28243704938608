/* OPERATIONS = REDUX THUNKS
This file defines the public interface of the duck -- what can be dispatched from components
Simple operations are just about forwarding an action creator, ex: simpleQuack
Complex operations involve returning a thunk that dispatches multiple actions in a certain order, ex: complexQuack
*/

import {SEND_TIPS} from "./types";


/*export function sendTips(tips) {

    return (dispatch) => {dispatch({
            type: SEND_TIPS,
            payload: {tips}
        });
    };

}*/

export function sendTips(values, setStatus, setSubmitting, resource, resetForm) {
    setTimeout(() => {
        setSubmitting(true);
        fetch("https://qhke0vtwij.execute-api.eu-north-1.amazonaws.com/prod/contact", {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(values)
        })
            .then(function () {
                setStatus(true);
            })
            .then(function () {
                setSubmitting(false);
            });
    }, 400);
}


export {};
