import {configureStore} from "@reduxjs/toolkit";
import {counterState} from "./ducks";
import {displayAlbumState} from "./ducks";
import {showPageState} from "./ducks";
import {contactFormState} from "./ducks";


export default configureStore({
    reducer: {
        counter: counterState,
        displayAlbum: displayAlbumState,
        showPage: showPageState,
        contactForm: contactFormState,
    },
});

