import {COLORS} from "../constants";
import React from "react";


const ColoredLine = ({style, ...rest}) => (
    <hr
        style={{
            ...{
                color: COLORS.primary,
                backgroundColor: COLORS.primary,
                height: "0.0001"
            },
            ...style
        }}
        {...rest}
    />
);

export default ColoredLine;
