// Tests example quotes from https://github.com/alexnm/re-ducks
//
const INIT_COUNTER = "app/counter/INIT_COUNTER";
const UPDATE_LIST = "app/counter/UPDATED_LIST";
const UPDATE_COUNTER = "app/counter/UPDATE_COUNTER";
const TICK_COUNTER = "app/counter/TICK_COUNTER";
const TIMER_TICK = "app/counter/TIMER_TICK";
const TIMER_START = "app/counter/TIMER_START";
const TIMER_STOP = "app/counter/TIMER_STOP";

export {
    INIT_COUNTER,
    UPDATE_LIST,
    UPDATE_COUNTER,
    TICK_COUNTER,
    TIMER_START,
    TIMER_STOP,
    TIMER_TICK
};
