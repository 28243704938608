import React from "react";
import Logo from "./Logo";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import LanguagePicker from "./LanguagePicker";
import Button from "react-bootstrap/Button";
import Text from "./Text";
import i18n from "../i18n";
import {useDispatch} from "react-redux";
import {useSelector} from "react-redux";
import {setActivePage_faq} from "../../state/ducks/showPage/operations";
import {setActivePage_exitCurrent} from "../../state/ducks/showPage/operations";
import {setActivePage_contact} from "../../state/ducks/showPage/operations";
import {setActivePage_grants} from "../../state/ducks/showPage/operations";
import {Link} from "react-router-dom";


const LogoAndLanguagePicker = () => {
        const dispatch = useDispatch();
        const page = useSelector(state => state.showPage).activePage;

        const showFAQ = () => {
            dispatch(setActivePage_faq());
        };

        const showConctact = () => {
            dispatch(setActivePage_contact());
        };

        const showGrants = () => {
            dispatch(setActivePage_grants());
        };

        const exitPage = () => {
            dispatch(setActivePage_exitCurrent());
        };

        return (
            <Row>
                <Col className="text-left" style={{marginTop: "1vw"}}>
                    <Link to={"/"}>
                        <Logo style={{marginLeft: "-1.8vw", marginBottom: "-1.2vw", marginTop: "-1.5vw"}}/>
                    </Link>
                </Col>
                <Col sm={4} md={4} lg={4} xl={4} className="mb-auto mt-auto pl-0">
                    <Row className="pl-0">
                        <Col xs={12} sm={12} className="d-none d-sm-block pl-0">
                            <Link to={"/faq"}>
                                <Button size="sm" variant={"link"} className="language-picker text-right ">
                                    <Text className=" language-picker-text"
                                          style={{textTransform: "uppercase", float: "right"}}>
                                        {i18n.t("faq.label")}
                                    </Text>
                                </Button>
                            </Link>

                        </Col>
                        <Col xs={12} sm={12} className="d-none d-sm-block">

                            <Link to={"/grants"}>

                                <Button size="sm" variant={"link"} className="language-picker text-right"

                                >
                                    <Text className=" language-picker-text"
                                          style={{textTransform: "uppercase", float: "right"}}>
                                        {i18n.t("grants.label")}
                                    </Text>
                                </Button>
                            </Link>
                            <Link to={"/contributors"}>
                                <Button size="sm" variant={"link"} className="language-picker text-right ">
                                    <Text className=" language-picker-text"
                                          style={{textTransform: "uppercase", float: "right"}}>
                                        {i18n.t("contributors.title")}
                                    </Text>
                                </Button>
                            </Link>
                            <Link to={"/contact"}>
                                <Button size="sm" variant={"link"} className="language-picker text-right"

                                >
                                    <Text className=" language-picker-text" style={{textTransform: "uppercase"}}>
                                        {i18n.t("tips.label")}
                                    </Text>
                                </Button>
                            </Link>
                            {/*<a target="_blank" href={"https://spleis.no/paf"}>
                                <Button size="sm" variant={"link"} className="language-picker text-right"

                                >
                                    <Text className=" language-picker-text"
                                          style={{textTransform: "uppercase"}}>
                                        SPLEIS
                                    </Text>
                                </Button>
                            </a>*/}
                        </Col>
                        <Col xs={12} sm={12} className="language-picker text-right">
                            <LanguagePicker/>

                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }


;

export default LogoAndLanguagePicker;
