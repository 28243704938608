import React from "react";
import Text from "./Text";
import Dropdown from "react-bootstrap/Dropdown";
import ChevronDownIcon from "./Icons/ChevronDownIcon";
import GlobeIcon from "./Icons/GlobeIcon2";
import i18n from "../i18n";

import Cookies from "universal-cookie";


const cookies = new Cookies();


const LanguagePicker = () => (
    <Dropdown
        className="language-picker zindex-popover" style={{width: "100%"}}
    >
        <Dropdown.Toggle id="language-picker">
            <div>
                <Text className="language-picker-text" style={{textTransform: "uppercase"}}>
                    <GlobeIcon  style={{marginTop: '-0.3em'}}/>
                </Text>
                <Text className="language-picker-text"
                      style={{
                          transform: "translateY(-50px)",
                          textTransform: "uppercase",
                          marginLeft: "0.5em",
                          marginRight: "0.5em"
                      }}>{i18n.t("language.label")}
                </Text>
                <Text className="language-picker-text" style={{textTransform: "uppercase"}}>
                    <ChevronDownIcon/>
                </Text>
            </div>
        </Dropdown.Toggle>

        <Dropdown.Menu className="zindex-popover popover-style-container">
            <Dropdown.Item className="zindex-popover popover-style"
                           onClick={(o) => {
                               cookies.set("lang", "no", {path: "/", secure: true});

                               window.location.reload(false);

                           }}
            >Norsk</Dropdown.Item>
            <Dropdown.Item className="zindex-popover popover-style"
                           onClick={(o) => {
                               cookies.set("lang", "en", {path: "/", secure: true});

                               window.location.reload(false);

                           }}
            >English</Dropdown.Item>
{/*            <Dropdown.Item className="zindex-popover text-right"
                           onClick={(o) => {
                               cookies.set("lang", "ar", {path: "/", secure: true});

                               window.location.reload(false);

                           }}
            >عربى</Dropdown.Item>*/}
        </Dropdown.Menu>
    </Dropdown>
);

export default LanguagePicker;
