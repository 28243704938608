import {SET_ACTIVE_ALBUM} from "./types";
import {SET_ACTIVE_TRACKLIST} from "./types";
import {produce} from "immer";
import {SET_MODAL_ACTIVE} from "./types";
import {SET_MODAL_COVERART} from "./types";


const initialState = {
    displayAlbum: {
        name: "SAS PLUS / SAS PUSSY (PAF)",
        id: "4f18babf-9429-43a4-baab-6e71c098f1df12",
        coverArtSrc: "/covers/SPSP_PAF_500.jpg",
        coverArtHighresSrc: "/covers/SPSP_PAF_1000.jpg",
        title: "\"SAS Plus SAS Pussy (PAF)\"  - 2019",

        tracks: [
            {
                name: "SAS Pussy"
            },
            {
                name: "مونا الحسين‎",
                ltr: true
            },
            {
                name: ":/"
            },
            {
                name: "ZALIL"
            },
            {
                name: "§ 393"
            },
            {
                name: "દફેજ‎ (5000 INR)"
            },
            {
                name: "MOSCOT & DIOR"
            },
            {
                name: "دودي الفايد‎",
                ltr: true
            },
            {
                name: "SYREANGREP"
            },
            {
                name: ":("
            },
            {
                name: "LOCK N' LOAD"
            },
            {
                name: "RAMSTADSLETTA"
            },
            {
                name: ":)"
            },
            {
                name: "BLOD, HOMIE"
            },
            {
                name: "فاروق القاسم‎",
                ltr: true
            },
            {
                name: "HESSELBERGS GATE 2, 3717 SKIEN"
            },
            {
                name: "DODI I EN HOODIE"
            }
        ]
    },
    activeTrackList: [
        {
            name: "SAS Pussy"
        },
        {
            name: "مونا الحسين‎",
            ltr: true
        },
        {
            name: ":/"
        },
        {
            name: "ZALIL"
        },
        {
            name: "§ 393"
        },
        {
            name: "દફેજ‎ (5000 INR)"
        },
        {
            name: "MOSCOT & DIOR"
        },
        {
            name: "دودي الفايد‎",
            ltr: true
        },
        {
            name: "SYREANGREP"
        },
        {
            name: ":("
        },
        {
            name: "LOCK N' LOAD"
        },
        {
            name: "RAMSTADSLETTA"
        },
        {
            name: ":)"
        },
        {
            name: "BLOD, HOMIE"
        },
        {
            name: "فاروق القاسم‎",
            ltr: true
        },
        {
            name: "HESSELBERGS GATE 2, 3717 SKIEN"
        },
        {
            name: "DODI I EN HOODIE"
        }
    ],
    modalActive: false,
    modalCoverArtSrc: undefined
};

export default function displayAlbumReducer(state = initialState, action) {
    switch (action.type) {
        case SET_ACTIVE_ALBUM: {
            return produce(state, (draft) => {
                draft.displayAlbum = action.payload.displayAlbum;
            });
        }

        case SET_ACTIVE_TRACKLIST: {
            return produce(state, (draft) => {
                if (action.payload.activeTrackList !== state.activeTrackList) {
                    if (action.payload.activeTrackList === undefined) {
                        draft.activeTrackList = [];
                    } else {
                        draft.activeTrackList = action.payload.activeTrackList;
                    }
                }
            });
        }

        case SET_MODAL_ACTIVE: {
            return produce(state, (draft) => {
                draft.modalActive = action.payload.modalActive;
            });
        }

        case SET_MODAL_COVERART: {
            return produce(state, (draft) => {
                draft.modalCoverArtSrc = action.payload.modalCoverArtSrc;
                draft.modalCoverArtHighresSrc = action.payload.modalCoverArtHighresSrc;
            });
        }

        default:
            return state;
    }
}
