import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Cookies from "universal-cookie";


const cookies = new Cookies();

if (cookies.get("lang") === undefined) {
    cookies.set("lang", "no", {path: "/", secure: true});
}

i18next
    .use(LanguageDetector)
    .init({
        interpolation: {
            // React already does escaping
            escapeValue: false,
        },
        fallbackLng: "no",
        lng: cookies.get("lang"),
        // Using simple hardcoded resources for simple example
        resources: {
            en: {
                translation: {
                    madebyrebell: {label: "Developed by HOSST"},
                    album: {label: "albums / EP's",},
                    single: {label: "singles",},
                    title: {label: "title",},
                    language: {label: "language",},
                    languageName: {label: "english",},
                    cookies: {
                        title: "Cookie policy",
                        text: "We use cookies to remember your chosen language, and third party cookies from Google Analytics for site usage analytics. Pr: https://www.nkom.no/internett/informasjonskapsler-cookies a technical setting in your browser flagging opt-out of cookies is considered consent. See https://nettvett.no/slik-administrer-du-informasjonskapsler/ for information about how to opt out."
                    },
                    privacy: {
                        title: "Privacy policy",
                        text: "Patel & Abdelmaguid Foundation AS (org.nr. 918 656 103) is responsible for the processing of personal data as described in this privacy policy. In this privacy policy, we explain what kind of personal data we store and how we process it. This privacy policy applies to: paf.no, www.paf.no\n" +
                            "\n" +
                            "Personal data we collect and process\n" +
                            "We process the following categories of personal data:\n" +
                            "\n" +
                            "Basic information: Name\n" +
                            "Contact information: Telephone number, email address\n" +
                            "The personal information is collected directly from you through information you provide to us and in connection with your use of our services.\n" +
                            "\n" +
                            "How we use the personal data\n" +
                            "Collection of tips\n" +
                            "To submit tips to us, we need your contact information.\n" +
                            "\n" +
                            "Your rights\n" +
                            "If you wish to exercise any of your rights, contact us via the contact form.\n" +
                            "\n" +
                            "Right to access your own information\n" +
                            "You can request a copy of all information we process about you. Contact us at the email address above to exercise your right of access.\n" +
                            "\n" +
                            "Right to correction of personal data\n" +
                            "You have the right to ask us to correct or supplement information that is incorrect or misleading.\n" +
                            "\n" +
                            "The right to delete personal data\n" +
                            "You have the right to have your personal data deleted without undue delay. You can therefore ask us to delete information about yourself at any time. But please note that information that we are required to keep due to other legal obligations (such as the Bookkeeping Act) will not be deleted.\n" +
                            "\n" +
                            "Limitation of processing of personal data\n" +
                            "In some situations, you can also ask us to limit the processing of information about you. You do this by managing consents or reservations in our solutions.\n" +
                            "\n" +
                            "Protest against the processing of personal data\n" +
                            "If we process information about you based on our tasks or on the basis of a balancing of interests, you have the right to object to our processing of information about you. You do this by managing consents or reservations in our solutions.\n" +
                            "\n" +
                            "Data portability\n" +
                            "You have the right to receive your personal data in a structured, commonly used and machine-readable format. Contact the email address above to have your personal data disclosed.\n" +
                            "\n" +
                            "You can complain about our processing of personal data\n" +
                            "We hope you will speak up if you think we are not complying with the rules in the Personal Data Act. Please let us know first through the contact or channel you have already established with us. You can also complain about our processing of personal data. You do this to the Norwegian Data Protection Authority."
                    },
                    counter: {label: "made since 17.08.2020", error: "Counter temp. unavailable"},
                    contributors: {
                        title: "Contributors",
                        text: "Throughout their career, Karpe has collaborated with a handful of producers. The producers listed below have given their share of the master rights to works from Karpe's catalog that were produced between 2006 and 2012\n",
                        list: [
                            {name: 'Carl Hovind', title : "produsent"},
                            {name: 'Jarl Aanestad', title : "produsent"},
                            {name: 'Martin "Tinnitus" Järnberg', title : "produsent"},
                            {name: 'Michel Flygare', title : "produsent"},
                            {name: 'Tobias "Astma" Jimson', title : "produsent"},
                            {name: 'Tom Roger Rogstad', title : "produsent"},
                            {name: 'William Wiik Larsen', title : "produsent"},
                        ]
                    },
                    tips: {
                        label: "Send us a tip",
                        name: {label: "Name", placeholder: "Your name", error: "Please fill out"},
                        email: {label: "Email", placeholder: "Your email address", error: "Please fill out"},
                        number: {label: "Phone number", placeholder: "Your mobile number", error: "Please fill out"},
                        text: {label: "Tips", placeholder: "Write us a short text", error: "Please fill out"},
                        submit: {label: "Submit",},
                        submitting: {label: "Submitting",},
                        thankyou: {
                            text: "Thank you for your enquiry! All submitted suggestions will be read and considered. Since this is a project that is operated on a non-profit basis, we do not have the capacity to respond to all the suggestions we receive.",
                            sign: "Best, \n" +
                                "Patel & Abdelmaguid Foundation"
                        }

                    },
                    grants: {
                        label: 'See Grants',
                        firstlabel: 'View the first grants',
                        title: 'Grants',
                        counterText: 'total granted',
                        goToWebpage: "Visit webpage"
                    },
                    faq: {
                        label: "FAQ",
                        what: {
                            q: "What is Patel & Abdelmaguid Foundation?",
                            a: "Chirag Rashmikant Patel and Magdi Omar Ytreeide Abdelmaguid are a Norwegian music group that goes by the name of ‘Karpe’. Since 2004, the group has released six studio albums, in addition to various EPs and singles. On 17 August 2020, Karpe gave away its master rights and revenue from all of these releases to causes relating to assistance for refugees, asylum seekers and/or immigrants. \n"
                        },
                        who: {
                            q: "Who can receive funds?",
                            a: "Projects and organisations that work to improve the conditions for refugees, asylum seekers and immigrations can receive funds. There are no specific requirements set for the size or catchment area of the project. Both worldwide projects and small, local projects may receive funds from the Fund, as long as its work is carried out in accordance with the Fund's overarching objectives. "
                        },
                        howGiven: {
                            q: "What is the procedure for distributions?",
                            a: "There will be one allocation in March of each year, with the first allocation in March 2022.\n" +
                                "\n" +
                                "Anyone may individually suggest projects and organizations that should be considered to the Board of Directors. Suggestions can be sent via a form on the Fund's website: www.paf.no.\n" +
                                "\n" +
                                " Since this is a project that is operated on a non-profit basis, the Fund does not have the capacity to respond to all the suggestions it receives."
                        },
                        howChosen: {
                            q: "How is the Board of Directors elected?",
                            a: "The highest body of the Fund is the General Meeting, which is currently composed of Chirag Rashmikant Patel and Magdi Omar Ytreeide Abdelmaguid, " +
                                "and they elect the Board of Directors. In consultation with the Board of Directors, the General Meeting determines who shall receive funds, in addition to the size of the annual distribution.\n " +
                                "The Board of Directors shall be configured with a view of ensuring the best possible safeguarding of the Fund's objectives. " +
                                "In 2021 the following persons were elected for the Board of Directors of the Patel & Abdelmaguid Foundation:\n\n" +
                                "Mushaga Bakenga\n" +
                                "Nasim Ahzami Raknerud\n" +
                                "Lea Mariero"
                        },
                        requisites: {
                            q: "What requirements are set for reporting?",
                            a: "Projects and/or organizations that receive allocations are obligated to report on how the funds have been spent. More detailed instructions regarding reporting are provided in connection with the allocation."
                        },
                        howCounterWorks: {
                            q: "How does the counter work?",
                            a: "The counter is based on an average of real-time monitoring of streams and sum based on the average of sales value. The numbers are taken from our distributor; The Orchard.\n" +
                                "For each settlement (streaming, sync, etc.) and / or external contribution, the counter is updated accordingly."
                        },
                        whatsIncluded: {
                            q: "Which income streams are included in the agreement?",
                            a: "All income related to the master's rights from all releases included in the agreement goes to PAF. This means all the money that would have gone to a record company goes to the fund and appears on the counter. Income from TONO, concerts, merch is not part of the master's rights."
                        },
                        contributors: {
                            q: "Who are the contributors and what does that mean?",
                            a: "Several of the producers Karpe has worked with throughout their career have chosen to give their share of the master rights to the fund. This means that their income from the works they have produced also ends up in the pot which is distributed to causes relating to assistance for refugees, asylum seekers and/or immigrants."
                        }
                    },
                }
            },
            no: {
                translation: {
                    madebyrebell: {label: "Utviklet av HOSST"},
                    album: {label: "album / ep-er",},
                    single: {label: "singler",},
                    title: {label: "tittel",},
                    language: {label: "språk",},
                    languageName: {label: "norsk, bokmål",},
                    cookies: {
                        title: "Cookies",
                        text: "Vi bruker cookies, både egne og tredjeparts. Våre egne brukes til å huske språkvalg, mens tredjepartcookies fra Google Analytics brukes for analyse av nettsidebruken. Pr: https://www.nkom.no/internett/informasjonskapsler-cookies er en teknisk innstilling i nettleseren din samtykke og vi respekterer denne. Se https://nettvett.no/slik-administrer-du-informasjonskapsler/ for informasjon om hvordan skru dette av. "
                    },
                    privacy: {
                        title: "Personvernserklæring",
                        text: "Patel & Abdelmaguid Foundation AS (org.nr. 918 656 103) er behandlingsansvarlig for behandlingen av personopplysninger som beskrevet i denne personvernerklæringen. I denne personvernerklæringen forklarer vi hva slags personopplysninger vi lagrer og hvordan vi behandler de. Denne personvernerklæringen gjelder for: paf.no, www.paf.no\n" +
                            "\n" +
                            "Personopplysninger vi samler inn og behandler\n" +
                            "Vi behandler følgende kategorier av personopplysninger:\n" +
                            "\n" +
                            "Grunnleggende informasjon: Navn\n" +
                            "Kontaktinformasjon: Telefonnummer, epostadresse\n" +
                            "Personopplysningene samles inn direkte fra deg gjennom informasjon du oppgir til oss og i forbindelse med at du kontakter oss.\n" +
                            "\n" +
                            "Hvordan vi bruker personopplysningene\n" +
                            "\n" +
                            "Innsamling av tips\n" +
                            "For innsending av tips til oss må vi ha din kontaktinformasjon.\n" +
                            "\n" +
                            "Dine rettigheter\n" +
                            "Dersom du ønsker å utøve noen av dine rettigheter, ta kontakt med oss via kontaktskjemaet på Tips oss-siden.\n" +
                            "\n" +
                            "Rett til innsyn i egne opplysninger\n" +
                            "Du kan be om en kopi av alle opplysninger vi behandler om deg. Ta kontakt på epostadressen ovenfor for å bruke innsynsretten din.\n" +
                            "\n" +
                            "Rett til korrigering av personopplysninger\n" +
                            "Du har rett til å be oss rette eller supplere opplysninger som er feilaktige eller misvisende.\n" +
                            "\n" +
                            "Retten til sletting av personopplysninger\n" +
                            "Du har rett til å få dine personopplysninger slettet uten ugrunnet opphold. Du kan derfor når som helst be oss slette opplysninger om deg selv. Men merk at informasjon som vi er pålagt beholde av hensyn til andre rettslige forpliktelser (som for eksempel bokføringsloven) ikke vil bli slettet.\n" +
                            "\n" +
                            "Dataportabilitet\n" +
                            "Du har rett til å få utlevert dine personopplysninger i et strukturert, alminnelig anvendt og maskinlesbart format. Ta kontakt på epostadressen ovenfor for å få utlevert dine personopplysninger.\n" +
                            "\n" +
                            "Du kan klage på vår behandling av personopplysninger\n" +
                            "Vi håper du vil si ifra dersom du mener vi ikke overholder reglene i personopplysningsloven. Si da gjerne først i fra gjennom den kontakten eller kanalen du allerede har etablert med oss. Du kan også klage over vår behandling av personopplysninger. Dette gjør du til Datatilsynet."
                    },
                    counter: {label: "opptjent siden 17.08.2020", error: "Teller midlertidig utilgjengelig"},
                    tips: {
                        label: "Tips oss",
                        name: {label: "Navn", placeholder: "Ditt navn", error: "Må fylles ut"},
                        email: {label: "Epost", placeholder: "Din epostadresse", error: "Må fylles ut"},
                        number: {label: "Telefonnummer", placeholder: "Ditt telefonnummer", error: "Må fylles ut"},
                        text: {label: "Tips", placeholder: "Skriv en kort tekst", error: "Må fylles ut"},
                        submit: {label: "Send",},
                        submitting: {label: "Sender",},
                        thankyou: {
                            text: "Takk for henvendelsen din! \n" +
                                " Alle innsendte tips blir lest og vurdert. Da dette er et prosjekt som drives på frivillig basis, \n" +
                                "har vi imidlertid ikke kapasitet til å respondere på alle tipsene vi mottar.",
                            sign: "Med vennlig hilsen, \n" +
                                "Patel & Abdelmaguid Foundation"
                        }
                    },
                    contributors: {
                        title: "Bidragsytere",
                        text: "Gjennom sin karriere har Karpe samarbeidet med en håndfull produsenter. Produsentene listet under har gitt sin andel av masterrettighetene på verk fra Karpes katalog som er produsert mellom 2006 og 2012:\n",
                        list: [
                            {name: 'Carl Hovind', title : "produsent"},
                            {name: 'Jarl Aanestad', title : "produsent"},
                            {name: 'Martin "Tinnitus" Järnberg', title : "produsent"},
                            {name: 'Michel Flygare', title : "produsent"},
                            {name: 'Tobias "Astma" Jimson', title : "produsent"},
                            {name: 'Tom Roger Rogstad', title : "produsent"},
                            {name: 'William Wiik Larsen', title : "produsent"},
                        ]
                    },
                    grants: {
                        label: 'Se tildelinger',
                        firstlabel: 'Se de første tildelingene',
                        title: 'Tildelinger',
                        counterText: 'utdelt',
                        goToWebpage: "Gå til nettside"
                    },
                    faq: {
                        label: "Ofte stilte spørsmål",
                        what: {
                            q: "Hva er Patel & Abdelmaguid Foundation?",
                            a: "Chirag Rashmikant Patel og Magdi Omar Ytreeide Abdelmaguid er en norsk musikkgruppe som går under navnet Karpe. Siden 2004 har gruppa gitt ut seks studioalbum, samt diverse EP-er og singler. Den 17. august 2020 ga Karpe bort masterrettighetene og inntektene fra alle disse utgivelsene til formål innen bistand for flyktninger, asylsøkere og/eller innvandrere."
                        },
                        who: {
                            q: "Hvem kan motta penger?",
                            a: "Prosjekter og organisasjoner som jobber for å bedre situasjonen til flyktninger, asylsøkere og innvandrere kan motta penger. " +
                                "Det stilles ingen spesielle krav til prosjektenes størrelse eller nedslagsfelt. Både verdensomspennende prosjekter og små lokale prosjekter kan motta midler fra fondet, så lenge arbeidet som gjøres er i tråd med fondets overordnede formål."
                        },
                        howGiven: {
                            q: "Hvordan foregår tildelingene?",
                            a: "Det vil være én tildeling hver vår, med første utdeling i mars 2022. " +
                                "Alle som ønsker det, kan enkelt tipse styret om prosjekter og organisasjoner som bør vurderes.\n" +
                                "Tips kan sendes via et skjema på fondets nettside: " +
                                "www.paf.no. \n\n" +
                                "Da dette er et prosjekt som drives på frivillig basis, har fondet imidlertid ikke kapasitet til å " +
                                "respondere på alle tipsene det mottar."
                        },
                        howChosen: {
                            q: "Hvordan velges styret?",
                            a: "Fondets øverste organ er generalforsamlingen, som per i dag består av Chirag Rashmikant Patel og Magdi Omar Ytreeide Abdelmaguid, " +
                                "og denne velger styret. Styret velger i samråd med generalforsamlingen hvem som skal motta midlene, samt størrelsen på den årlige utdelingen. " +
                                "Styret skal sammensettes med sikte på å sikre en best mulig ivaretakelse av fondets formål. Pr. 2023 er følgende personer i styret " +
                                "til Patel & Abdelmaguid Foundation:\n\n" +
                                "Mushaga Bakenga\n" +
                                "Nasim Ahzami Raknerud\n" +
                                "Lea Mariero"
                        },
                        requisites: {
                            q: "Hvilke krav stilles til rapportering?",
                            a: "Prosjekter og/eller organisasjoner som mottar tildelinger plikter å rapportere om hva pengene har blitt brukt til. Nærmere instrukser med hensyn til rapportering gis i forbindelse med tildelingen."
                        },
                        howCounterWorks: {
                            q: "Hvordan fungerer telleren?",
                            a: "Telleren baserer seg på et gjennomsnitt av realtime monitorering av streams og sum basert på gjennomsnitt av salgsverdi. \n" +
                                "Tallene er hentet fra vår distributør; The Orchard. For hver avregning (streaming, sync o.l.) og/eller eksternt bidrag oppdateres telleren tilsvarende."
                        },
                        whatsIncluded: {
                            q: "Hvilke inntekter inngår i avtalen?",
                            a: "Alle inntekter knyttet til masterrettighetene fra alle utgivelsene frem til nå går til PAF. Dette innebærer alle pengene som ville gått til et plateselskap går til fondet og fremgår av telleren. Inntekter fra TONO, konserter og merch er ikke en del av masterrettighetene."
                        },
                        contributors: {
                            q: "Hvem er bidragsyterne og hva betyr det?",
                            a: "Flere av produsentene Karpe har jobbet med gjennom karrieren har valgt å gi sin andel av masterrettighetene til fondet. Det vil si at deres inntekter fra verkene de har produsert også havner i potten som deles ut til formål innen bistand for flyktninger, asylsøkere og/eller innvandrere."
                        }


                    },
                },
            },
            /*ar: {
                translation: {
                    album: {label: "ألبوم",},
                    single: {label: "singleألبوم",},
                    title: {label: "عنوان",},
                    language: {label: "لغة",},
                    languageName: {label: "عربىَ",},
                    cookies: {
                        title: "سياسة ملفات الارتباط",
                        text: "نحن نستخدم ملفات تعريف الارتباط لتذكر اللغة التي اخترتها وملفات تعريف ارتباط الطرف الثالث من Google Analytics لتحليلات استخدام الموقع."
                    },
                    counter: {label: "نحن نستخدم ملفات تعريف الارتباط لتذكر"},
                    tips: {label: "سياسةسياسة"},
                    faq: {label: "سياسة"},
                },
            },*/
        },

    })
;

const ltr = () => {
    return (i18next.dir() === "ltr");
};

export default i18next;


export {ltr};
