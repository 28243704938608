import React from "react";
import i18n from "../i18n";
import {ltr} from "../i18n";
import Text from "./Text";
import FadeIn from "./FadeIn";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import ColoredLine from "./ColoredLine";
import {ViewHeader} from "./ViewHeader";


const questions = [
    "what", "who", "howGiven", "howChosen", "requisites", "howCounterWorks", "whatsIncluded", "contributors"
];

const FAQ = () => {

    return (

        <div className='faq-container'>
            <ViewHeader title={i18n.t("faq.label")}/>
            <Row>
                <Col className={!ltr() ? "text-right" : ""}>
                    {questions.map((q, idx) => {
                        return (
                            <FadeIn className='faq-line d-block' key={"faq" + idx} fadeTime={400} order={idx}>
                                <Text className='faq-question d-block'>{i18n.t("faq." + q + ".q")}</Text>
                                <Text className='faq-answer d-block'>{i18n.t("faq." + q + ".a")}</Text>
                            </FadeIn>
                        );
                    })}
                </Col>
            </Row>
            <FadeIn fadeTime={400} order={8}>
                <ColoredLine/>
            </FadeIn>
        </div>

    );
};

export default FAQ;
