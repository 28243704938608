/* OPERATIONS = REDUX THUNKS
This file defines the public interface of the duck -- what can be dispatched from components
Simple operations are just about forwarding an action creator, ex: simpleQuack
Complex operations involve returning a thunk that dispatches multiple actions in a certain order, ex: complexQuack
*/

import {UPDATE_LIST} from "./types";
import {TIMER_TICK} from "./types";
import {TIMER_START} from "./types";
import {TIMER_STOP} from "./types";
import {API_BASE_URL} from "../../../views/constants";
import {axios} from "../../../utils";


export function getCounterList() {
    const request = axios.get(API_BASE_URL + "/counter");

    return (dispatch) => {
        request.then((response) => dispatch({
            type: UPDATE_LIST,
            payload: response.data
        }));
    };
}


let timer = null;
let fetch_timer = null;

export const start = () => (dispatch) => {
    clearInterval(timer);


    fetch_timer = setInterval(() => dispatch(getCounterList()), 60 * 1000);
    dispatch(getCounterList());

    timer = setInterval(() => dispatch(tick()), 200);
    dispatch({type: TIMER_START});
    dispatch(tick());
};

const tick = () => {
    return {type: TIMER_TICK};
};

export const stop = () => {
    clearInterval(timer);
    clearInterval(fetch_timer);
    return {type: TIMER_STOP};
};

export {};
