import React from "react";
import {Fragment} from "react";
import {ALBUMS} from "../constants";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {connect} from "react-redux";
import {useDispatch} from "react-redux";
import {setActiveAlbum} from "../../state/ducks/displayAlbum/operations";
import {setActiveTracklist} from "../../state/ducks/displayAlbum/operations";
import {setModalActive} from "../../state/ducks/displayAlbum/operations";
import {setModalCoverArtSrc} from "../../state/ducks/displayAlbum/operations";
import Tilt from "react-parallax-tilt";
import ColoredLine from "./ColoredLine";
import FadeIn from "./FadeIn";
import Text from "./Text";
import i18n from "../i18n";
import {ltr} from "../i18n";
import {useMediaQuery} from "react-responsive";
import ProgressiveImage from "react-progressive-image-loading";
import {SINGLES} from "../constants";


const TiltView = ({album}) => {
    const dispatch = useDispatch();
    const isMobile = useMediaQuery({query: "(max-width: 768px)"});

    return (
        <Fragment>
            <Tilt
                tiltReverse
                glareMaxOpacity={0.1}
                tiltMaxAngleX={15}
                tiltMaxAngleY={15}
                key={album.id}
            >


                <ProgressiveImage
                    key={album.modalCoverArtSrc}
                    preview={album.coverArtThumbSrc}
                    src={album.coverArtSrc}
                    aria-label={"album cover art"}
                    alt={""}

                    transitionTime={500}
                    style={{width: "100%", cursor: "pointer"}}
                    transitionFunction="ease"
                    render={(src, style) => <img alt={""} src={src} style={{width: "100%", cursor: "pointer"}}
                                                 onClick={() => {
                                                     if (!isMobile) {

                                                         dispatch(setActiveAlbum(album));
                                                         dispatch(setModalCoverArtSrc(album));
                                                         if (album.tracks !== undefined) {
                                                             dispatch(setActiveTracklist(album.tracks));
                                                         } else {
                                                             dispatch(setActiveTracklist([]));
                                                         }
                                                         dispatch(setModalActive(true));
                                                     }
                                                 }
                                                 }/>}
                />

            </Tilt>
            <div className='d-block d-sm-none' style={{marginLeft: "-2.5vw", marginRight: "-2.5vw"}}>
                <div style={{paddingBottom: "1.5em", paddingTop: "0.8em"}}>
                    <Text
                        className='album-cover-title'>{album.titleMobile === undefined ? album.title : album.titleMobile}</Text>
                </div>
                <div style={{paddingBottom: "1em",}}>
                    {album.tracks !== undefined && !album.hideTracklistOnMobile ?
                        album.tracks.map((track, order) => {
                            let className = "";
                            className += track.ltr ? " text-right" : "";
                            className += " tracklist-nohover";
                            const colWidth = track.timecode ? 9 : 12;
                            return (<FadeIn key={track.name + order} order={order} fadeTime={500}>
                                    <Row
                                        style={{
                                            padding: 0,
                                            paddingTop: "1vw",
                                            margin: 0
                                        }}
                                    >
                                        {track.timecode ?
                                            <Col xs={3} style={{
                                                padding: 0,
                                                margin: 0
                                            }}>
                                                <div className='tracklist-nohover'><Text
                                                    className={"album-cover-text"}>{track.timecode}</Text>
                                                </div>
                                            </Col>
                                            : ""}
                                        <Col xs={colWidth} style={{
                                            padding: 0,
                                            margin: 0
                                        }}>
                                            <div key={track.name + order} onMouseOver={() => {

                                            }}

                                                 style={{
                                                     padding: "0",
                                                     paddingTop: "0",
                                                     marginBottom: "0.3vw",
                                                     width: "100%"

                                                 }}
                                                 className={className}>

                                                <Text className={"album-cover-text " + album.overrideTracklistClassName}
                                                      style={{margin: 0}}>
                                                    {track.name}
                                                </Text>

                                            </div>
                                        </Col>
                                    </Row>
                                </FadeIn>
                            );
                        })

                        : ""}

                </div>
            </div>
            <ColoredLine style={{margin: "-4vw", marginTop: "4vw"}}
                         className='d-block d-sm-none'/>
        </Fragment>
    );
};


class CatalogGrid extends React.Component {
    constructor(props) {
        super(props);

        const albumsPrLine = 4
        var albumRows = []
        var singleRows = []

        for (var i = 0; ALBUMS.length / albumsPrLine > i; i++) {
            albumRows.push(ALBUMS.slice(albumsPrLine*i, albumsPrLine*(i+1)))
        }
        for (var j = 0; SINGLES.length / albumsPrLine > j; j++) {
            singleRows.push(SINGLES.slice(albumsPrLine*j, albumsPrLine*(j+1)))
        }

        this.state = {
            activeAlbum: ALBUMS[0],
            loading: true,
            loadedImages: 0,
            albumsPrLine: albumsPrLine,
            fadeTime: 400,
            fadeOrderOffset: 0,
            singleRows: singleRows,
            albumRows: albumRows
        };

        this.countLoadedImages = this.countLoadedImages.bind(this);

    }

    countLoadedImages() {
        this.setState({loadedImages: this.state.loadedImages + 1}, () => {
        });
    }

    componentDidMount() {
    }

    render() {

        return (
            <Row style={{
                marginRight: 0,
                marginLeft: 0,
                height: "100%",
            }}

            >

                <Col
                    style={{
                        paddingLeft: 0,
                        paddingRight: 0,

                    }}
                    xs={12}
                >
                    {this.state.albumRows.map((albums, row) => {
                        return(<Row style={{
                            marginRight: 0,
                            marginLeft: 0,
                            paddingTop: "1.5vw"
                        }}

                        >
                            {albums.map((album, idx) => {
                                return (
                                    <Col key={idx} xs={12} sm={3}>
                                        <FadeIn fadeTime={this.state.fadeTime} order={this.state.fadeOrderOffset + idx + 4*row}>
                                            <TiltView album={album}/>
                                        </FadeIn>
                                    </Col>
                                );
                            })}

                        </Row>)
                    })}

                    <FadeIn fadeTime={this.state.fadeTime} order={4*this.state.albumRows.length}>
                        <ColoredLine className={"d-none d-sm-block"} style={{marginTop: "2vw"}}/>
                    </FadeIn>
                    <div className={!ltr() ? "text-right album-list-title" : "album-list-title"}
                         style={{marginTop: "1.5vw"}}>
                        <FadeIn order={9} fadeTime={400}>
                            <Text style={{textTransform: "uppercase",}}
                                  fontSize='1.5em'>{i18n.t("single.label")}
                            </Text>
                        </FadeIn>
                    </div>
                    {this.state.singleRows.map((singles, row) => {
                        return(<Row style={{
                            marginRight: 0,
                            marginLeft: 0,
                            paddingTop: "1.5vw"
                        }}

                        >
                            {singles.map((album, idx) => {
                                return (
                                    <Col key={idx} xs={12} sm={3}>
                                        <FadeIn fadeTime={this.state.fadeTime} order={this.state.fadeOrderOffset + idx + 4*(this.state.albumRows.length+row)}>
                                            <TiltView album={album}/>
                                        </FadeIn>
                                    </Col>
                                );
                            })}

                        </Row>)
                    })}
                    <FadeIn fadeTime={this.state.fadeTime} order={4*(this.state.albumRows.length+this.state.singleRows.length)-2}>
                        <ColoredLine className={"d-none d-sm-block"} style={{marginTop: "2vw"}}/>
                    </FadeIn>

                </Col>
            </Row>

        );
    }

    setAlbumAsHighlighted(album, trackList) {
        this.props.setActiveAlbum(album);

        if (trackList !== undefined) {
            this.props.setActiveTracklist(trackList);
        } else {
            this.props.setActiveTracklist([]);
        }
    }
}


const mapDispatchToProps = dispatch => {
    return {
        setActiveAlbum: (album) => {
            dispatch(setActiveAlbum(album));
        },
        setActiveTracklist: (tracklist) => {
            dispatch(setActiveTracklist(tracklist));
        },
    };
};

const mapStateToProps = state => ({
    ...state
});

export default connect(mapStateToProps, mapDispatchToProps)(CatalogGrid);

