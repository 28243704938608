const COLORS = {
    text: "#fdd9c3",
    background: "rgba(32,23,33,1)",
    primary: "#fdd9c3",

};
//const API_BASE_URL = "https://api.paf.no";
const API_BASE_URL = "https://qhke0vtwij.execute-api.eu-north-1.amazonaws.com/prod";
export {
    COLORS,
    API_BASE_URL
};
export const ALBUMS = [
    {
        name: "SAS PLUS / SAS PUSSY (PAF)",
        id: "4f18babf-9429-43a4-baab-6e71c098f1df12",
        coverArtThumbSrc: "/covers/SPSP_PAF_100.jpg",
        coverArtSrc: "/covers/SPSP_PAF_500.jpg",
        coverArtHighresSrc: "/covers/SPSP_PAF_1000.jpg",
        title: "\"SAS PLUS / SAS PUSSY (PAF VERSION)\"  - 2020",
        titleMobile: "\"SAS PLUS / SAS PUSSY (PAF)\"  - 2020",
        overrideTracklistClassName: "album-compensate-tracklist-capslock",
        tracks: [
            {
                name: "SAS Pussy",
                timecode: "00:00"
            },
            {
                name: "مونا الحسين‎",
                ltr: true,
                timecode: "03:41"
            },
            {
                name: ":/",
                timecode: "04:06"
            },
            {
                name: "ZALIL",
                timecode: "04:51"
            },
            {
                name: "§ 393",
                timecode: "06:47"
            },
            {
                name: "દફેજ‎ (5000 INR)",
                timecode: "08:10"
            },
            {
                name: "MOSCOT & DIOR",
                timecode: "08:50"
            },
            {
                name: "دودي الفايد‎",
                ltr: true,
                timecode: "12:16"
            },
            {
                name: "SYREANGREP",
                timecode: "14:07"
            },
            {
                name: ":(",
                timecode: "16:53"
            },
            {
                name: "LOCK N' LOAD",
                timecode: "17:52"
            },
            {
                name: "RAMSTADSLETTA",
                timecode: "20:50"
            },
            {
                name: ":)",
                timecode: "21:29"
            },
            {
                name: "BLOD, HOMIE",
                timecode: "22:33"
            },
            {
                name: "فاروق القاسم‎",
                ltr: true,
                timecode: "24:30"
            },
            {
                name: "HESSELBERGS GATE 2, 3717 SKIEN",
                timecode: "25:35"
            },
            {
                name: "DODI I EN HOODIE",
                timecode: "25:57"
            }
        ]
    },
    {
        name: "SAS PLUS / SAS PUSSY",
        id: "4f18babf-9429-43a4-baab-6e71c098f1df1",
        coverArtThumbSrc: "/covers/SPSP_100.jpg",
        coverArtSrc: "/covers/SPSP_500.jpg",
        coverArtHighresSrc: "/covers/SPSP_1000.jpg",
        title: "\"SAS PLUS / SAS PUSSY\" - 2019",
        hideTracklistOnMobile: true,

        tracks: [
            {
                name: "SAS Pussy",
                timecode: "00:00"
            },
            {
                name: "مونا الحسين‎",
                ltr: true,
                timecode: "03:41"
            },
            {
                name: ":/",
                timecode: "04:06"
            },
            {
                name: "ZALIL",
                timecode: "04:51"
            },
            {
                name: "§ 393",
                timecode: "06:47"
            },
            {
                name: "દફેજ‎ (5000 INR)",
                timecode: "08:10"
            },
            {
                name: "MOSCOT & DIOR",
                timecode: "08:50"
            },
            {
                name: "دودي الفايد‎",
                ltr: true,
                timecode: "12:16"
            },
            {
                name: "SYREANGREP",
                timecode: "14:07"
            },
            {
                name: ":(",
                timecode: "16:53"
            },
            {
                name: "LOCK N' LOAD",
                timecode: "17:52"
            },
            {
                name: "RAMSTADSLETTA",
                timecode: "20:50"
            },
            {
                name: ":)",
                timecode: "21:29"
            },
            {
                name: "BLOD, HOMIE",
                timecode: "22:33"
            },
            {
                name: "فاروق القاسم‎",
                ltr: true,
                timecode: "24:30"
            },
            {
                name: "HESSELBERGS GATE 2, 3717 SKIEN",
                timecode: "25:35"
            },
            {
                name: "DODI I EN HOODIE",
                timecode: "25:57"
            }
        ]
    },
    {
        name: "Heisann Montebello",
        id: "4f18babf-9429-43a4-baab-6e71c098f1df2",
        coverArtThumbSrc: "/covers/HeisannMontebello_100.jpg",
        coverArtSrc: "/covers/HeisannMontebello_500.jpg",
        coverArtHighresSrc: "/covers/HeisannMontebello_1000.jpg",
        title: "\"Heisann Montebello\" - 2017",
        useImageFade: true,
        tracks: [
            {
                name: "Rett i foret",
                id: "4f18babf-9429-43a4-baab-6e71c098f1df213",
                coverArtThumbSrc: "/covers/rettiforet_100.jpg",
                coverArtSrc: "/covers/rettiforet_500.jpg",
                coverArtHighresSrc: "/covers/rettiforet_500.jpg",
                //coverArtHighresSrc: "/covers/rettiforet_1000.jpg",
                title: "\"Rett i foret\" - 2017",

            },
            {
                name: "Dup-i-dup",
                id: "4f18babf-9429-43a4-baab-6e71c098f1df212",
                coverArtThumbSrc: "/covers/dupidup_100.jpg",
                coverArtSrc: "/covers/dupidup_500.jpg",
                coverArtHighresSrc: "/covers/dupidup_500.jpg",
                //coverArtHighresSrc: "/covers/dupidup_1000.jpg",
                title: "\"Dup-i-dup\" - 2017",

            },
            {
                name: "Gunerius",
                id: "4f18babf-9429-43a4-baab-6e71c098f1df26",
                coverArtThumbSrc: "/covers/Gunerius_100.jpg",
                coverArtSrc: "/covers/Gunerius_500.jpg",
                coverArtHighresSrc: "/covers/Gunerius_500.jpg",
                //coverArtHighresSrc: "/covers/Gunerius_1000.jpg",
                title: "\"Gunerius\" - 2016",
            },
            {
                name: "Den islamske elefanten",
                id: "4f18babf-9429-43a4-baab-6e71c098f1df25",
                coverArtThumbSrc: "/covers/IslamskeElefanten_100.jpg",
                coverArtSrc: "/covers/IslamskeElefanten_500.jpg",
                coverArtHighresSrc: "/covers/IslamskeElefanten_500.jpg",
                //coverArtHighresSrc: "/covers/IslamskeElefanten_1000.jpg",
                title: "\"Den islamske elefanten\" - 2016",
            },
            {
                name: "Attitudeproblem",
                id: "4f18babf-9429-43a4-baab-6e71c098f1df24",
                coverArtThumbSrc: "/covers/AttitudeProblem_100.jpg",
                coverArtSrc: "/covers/AttitudeProblem_500.jpg",
                coverArtHighresSrc: "/covers/AttitudeProblem_500.jpg",
                //coverArtHighresSrc: "/covers/AttitudeProblem_1000.jpg",
                title: "\"Attitudeproblem\" - 2016",
            },
            {
                name: "Hus/Hotell/Slott/Brenner",
                id: "4f18babf-9429-43a4-baab-6e71c098f1df23",
                coverArtThumbSrc: "/covers/HusHotel_100.jpg",
                coverArtSrc: "/covers/HusHotel_500.jpg",
                coverArtHighresSrc: "/covers/HusHotel_500.jpg",
                //coverArtHighresSrc: "/covers/HusHotel_1000.jpg",
                title: "\"Hus/Hotell/Slott/Brenner\" - 2016",
            },
            {
                name: "Lett å være rebell i kjellerleiligheten din",
                id: "4f18babf-9429-43a4-baab-6e71c098f1df21",
                coverArtThumbSrc: "/covers/Rebell_100.jpg",
                coverArtSrc: "/covers/Rebell_500.jpg",
                coverArtHighresSrc: "/covers/Rebell_500.jpg",
                //coverArtHighresSrc: "/covers/Rebell_1000.jpg",
                title: "\"Lett å være rebell i kjellerleiligheten din\" - 2015",
            },
            {
                name: "Hvite menn som pusher 50",
                id: "4f18babf-9429-43a4-baab-6e71c098f1df213",
                coverArtThumbSrc: "/covers/HviteMenn_100.jpg",
                coverArtSrc: "/covers/HviteMenn_500.jpg",
                coverArtHighresSrc: "/covers/HviteMenn_500.jpg",
                //coverArtHighresSrc: "/covers/HviteMenn_1000.jpg",
                title: "\"Hvite menn som pusher 50\" - 2015",
            },
            {
                name: "Au Pair",
                id: "4f18babf-9429-43a4-baab-6e71c098f1df22",
                coverArtThumbSrc: "/covers/AuPair_100.jpg",
                coverArtSrc: "/covers/AuPair_500.jpg",
                coverArtHighresSrc: "/covers/AuPair_500.jpg",
                //coverArtHighresSrc: "/covers/AuPair_1000.jpg",
                title: "\"Au Pair\" - 2015",
            },
        ].reverse()
    },
    {
        name: "Kors på halsen, ti kniver i hjerter,\n mor og far i døden",
        id: "4f18babf-9429-43a4-baab-6e71c098f1df4",
        coverArtThumbSrc: "/covers/TiKniver_100.jpg",
        coverArtSrc: "/covers/TiKniver_500.jpg",
        coverArtHighresSrc: "/covers/TiKniver_1000.jpg",
        title: "\"Kors på halsen, ti kniver i hjerter, mor og far i døden\" - 2012",
        tracks: [
            {
                name: "Sakte film"
            },
            {
                name: "Jens"
            },
            {
                name: "Spis din syvende sans"
            },
            {
                name: "Helseguru feat. Truls Heggero"
            },
            {
                name: "Porselen"
            },
            {
                name: "Her"
            },
            {
                name: "Naomi feat. Fadlabi"
            },
            {
                name: "Påfugl feat. Yosef Wolde-Miriam"
            },
            {
                name: "Toyota'n til Magdi"
            },
            {
                name: "Støv feat. Kråkesølv"
            },
            {
                name: "Nyrestein"
            },
            {
                name: "Skåler med blod"
            },
            {
                name: "Bonus: Påfugl feat. Maria Mena"
            }
        ]
    },
    {
        name: "Aldri Solgt En Løgn",
        id: "4f18babf-9429-43a4-baab-6e71c098f1df5",
        coverArtThumbSrc: "/covers/AldriSolgtEnLøgn_100.jpg",
        coverArtSrc: "/covers/AldriSolgtEnLøgn_500.jpg",
        coverArtHighresSrc: "/covers/AldriSolgtEnLøgn_1000.jpg",
        title: "\"Aldri solgt en løgn\" - 2010",
        tracks: [
            {
                name: "Aldri solgt en løgn"
            },
            {
                name: "Sminke feat. Masta Ace"
            },
            {
                name: "Snøvit"
            },
            {
                name: "Blood"
            },
            {
                name: "Byduer i dur"
            },
            {
                name: "Rimblokk feat. Pumba"
            },
            {
                name: "Tusen tegninger"
            },
            {
                name: "Ruter feat. Andreas Grega"
            },
            {
                name: "Halla lille nykommer"
            },
            {
                name: "Ett under par"
            },
            {
                name: "Farlig feat. Tshawe"
            },
            {
                name: "Hvit hval"
            },
            {
                name: "Tog"
            }
        ]
    },
    {
        name: "Fire Vegger",
        id: "4f18babf-9429-43a4-baab-6e71c098f1df6",
        coverArtThumbSrc: "/covers/FireVegger_100.jpg",
        coverArtSrc: "/covers/FireVegger_500.jpg",
        coverArtHighresSrc: "/covers/FireVegger_1000.jpg",
        title: "\"Fire vegger\" - 2008",
        tracks: [
            {
                name: "Stjerner"
            },
            {
                name: "Regnvær"
            },
            {
                name: "Fire vegger"
            },
            {
                name: "Vestkantsvartinga"
            },
            {
                name: "Under overflaten (som Marit Larsen)"
            },
            {
                name: "OK"
            },
            {
                name: "Kaffe"
            },
            {
                name: "Krølla 50'lapp y’all"
            },
            {
                name: "Skavanker"
            },
            {
                name: "Få som vet"
            },
            {
                name: "Solskinn"
            },
            {
                name: "Fireogtyvegods"
            },
            {
                name: "Fly, baba"
            }
        ]
    },
    {
        name: "Rett Fra Hjertet",
        id: "4f18babf-9429-43a4-baab-6e71c098f1df7",
        coverArtThumbSrc: "/covers/RettfraHjertet_100.jpg",
        coverArtSrc: "/covers/RettfraHjertet_500.jpg",
        coverArtHighresSrc: "/covers/RettfraHjertet_1000.jpg",
        title: "\"Rett Fra Hjertet\" - 2006",
        tracks: [
            {
                name: "Rett fra hjertet"
            },
            {
                name: "Piano"
            },
            {
                name: "Galskap"
            },
            {
                name: "Identitet som dreper"
            },
            {
                name: "Fem om morningen"
            },
            {
                name: "Hat & Love"
            },
            {
                name: "Eksamen"
            },
            {
                name: "Hørt det før"
            },
            {
                name: "Kampen mot klokka"
            },
            {
                name: "Ridveda 10.90"
            },
            {
                name: "Show"
            },
            {
                name: "Déjà vu"
            }
        ]
    },
    {
        name: "Glasskår",
        id: "4f18babf-9429-43a4-baab-6e71c098f1df8",
        coverArtThumbSrc: "/covers/Glasskår_100.jpg",
        coverArtSrc: "/covers/Glasskår_500.jpg",
        coverArtHighresSrc: "/covers/Glasskår_1000.jpg",
        title: "\"Glasskår\" - 2004",
        tracks: [
            {
                name: "Skjønner du?"
            },
            {
                name: "NM i drittsekk"
            },
            {
                name: "Kunsten å være inder"
            },
            {
                name: "Generalens eiendom"
            },
            {
                name: "Glasskår"
            },
            {
                name: "Vi drepte DJ'n"
            },
        ]
    },
];

export const SINGLES = [
    {
        name: "PAF.no",
        id: "4f18babf-9429-43a4-baab-6e71c098f1df2112",
        coverArtThumbSrc: "/covers/PAF_100.jpg",
        coverArtSrc: "/covers/PAF_500.jpg",
        coverArtHighresSrc: "/covers/PAF_1000.jpg",
        title: "\"PAF.no\" - 2022",
    },
    {
        name: "skittles",
        id: "4f18babf-9429-43a4-baab-6e71c098f1df10",
        coverArtThumbSrc: "/covers/Skittles_100.jpg",
        coverArtSrc: "/covers/Skittles_500.jpg",
        coverArtHighresSrc: "/covers/Skittles_1000.jpg",
        title: "\"skittles\" - 2019",
    },
    {
        name: "Sulten 2",
        id: "4f18babf-9429-43a4-baab-6e71c098f1df2111",
        coverArtThumbSrc: "/covers/Sulten_100.jpg",
        coverArtSrc: "/covers/Sulten_500.jpg",
        coverArtHighresSrc: "/covers/Sulten_1000.jpg",
        title: "\"Sulten 2\" - 2017",
    },

];


export const GRANTS = [
    {
        grantee: "Mottaksklassen ved Børstad ungdomsskole",
        sum: 30000,
        reason: {
            no: "Prosjektet «Sykkelglede for jenter» ved Børstad ungdomsskole i Hamar har som mål å gi nyankomne jenter fra blant annet Kongo, Somalia, Afghanistan og Syria muligheten til å lære å sykle. Mange av disse jentene opplever begrensninger grunnet foreldres, besteforeldres og samfunnets generelle bekymringer for deres jomfruhinne og sikkerhet. Dette hindrer dem i å delta i en vanlig aktivitet i nærmiljøet. Gjennom å tilrettelegge for sykkelopplæring ønsker PAF å fremme selvstendighet, frihet og mosjon blant disse jentene.",
            en: "The project \"Bike Joy for Girls\" at Børstad Secondary School in Hamar aims to provide newly arrived girls from countries such as Congo, Somalia, Afghanistan, and Syria with the opportunity to learn how to ride a bike. Many of these girls face restrictions due to concerns from parents, grandparents, and society in general regarding their virginity and safety. This prevents them from participating in common activities in their community. By facilitating bike training, PAF aims to promote independence, freedom, and physical activity among these girls."
        },
        href: "https://www.hamar.kommune.no/skolene/ungdomsskolene/borstad-ungdomsskole/",
        date: "29.04.2024"
    },
    {
        grantee: "Trysil kommune",
        sum: 31500,
        reason: {
            no: "Med støtte fra PAF, vil Trysil kommune, i samarbeid med Trysil kirkelige fellesråd, arrangere en tur til Hunderfossen for familier med lav inntekt. Dette tiltaket er rettet mot å gi denne gruppen, som ikke alltid har mulighet til å gi barna sine en sommerferietur, en minneverdig opplevelse. Pengene vil gå til å dekke kostnadene for inngangsbilletter, busstransport og forpleining i løpet av dagen.",
            en: "With support from PAF, the Trysil Church Council will organize a trip to Hunderfossen for low-income families. This initiative aims to provide this group, who may not always have the means to offer their children a summer vacation, with a memorable experience. The funds will cover the costs of entrance tickets, bus transportation, and refreshments throughout the day."
        },
        date: "29.04.2024"
    },
    {
        grantee: "Foreningen MAKS",
        sum: 70000,
        reason: {
            no: "Foreningen MAKS er dedikert til å støtte kvinner med flyktning- og innvandrerbakgrunn gjennom aktiviteter som fremmer mestring, livsglede, språklæring og kvalifisering for arbeidslivet. Med støtte fra PAF planlegger de å arrangere Bakgårdsfesten i Landsbyen Dokka, en begivenhet fylt med mat, musikk og fellesskap. I tillegg vil de motta støtte til å anskaffe et stabilt, fungerende kjøleskap, noe som er avgjørende for å opprettholde matkvaliteten og sikre deres troverdighet.\n",
            en: "The MAKS Association is dedicated to supporting women with refugee and immigrant backgrounds through activities that promote empowerment, joy, language acquisition, and qualification for employment. With support from PAF, they will organize the Backyard Festival in the Village of Dokka, featuring food, music, and community. Additionally, they will receive funding to purchase a functional refrigerator, essential for maintaining food quality and ensuring their credibility.\n"
        },
        href: "https://www.facebook.com/ForeningenMaks/?locale=nb_NO",
        date: "29.04.2024"
    },
    {
        grantee: "Femti-71",
        sum: 250000,
        reason: {
            no: "Prosjektet \"Etter Skolen\" fra Femti-71 tilbyr et alternativt fritidstilbud for barn og unge i Bergen og omegn. I en tid hvor ungdomskriminaliteten øker, og kriminelle blir stadig yngre, er det avgjørende å engasjere disse ungdommene i positive aktiviteter. Prosjektet gir gratis aktiviteter etter skoletid, inkludert redesign, beatmaking/musikk, DJ-ing, dans og basketball i åpen hall med Girson. Med støtte fra PAF kan Femti-71 fortsette å tilby et trygt og engasjerende miljø hvor unge kan utvikle seg.\n",
            en: "The \"After School\" project by Femti-71 offers an alternative leisure activity for children and youth in Bergen and surrounding areas. At a time when youth crime is increasing and criminals are becoming younger, it is crucial to engage these youth in positive activities. The project provides free activities after school, including clothes redesign, beatmaking/music, DJ-ing, dance, and basketball in an open hall with Girson. With support from PAF, Femti-71 can continue to offer a safe and engaging environment where young people can develop.\n"
        },
        href: "https://www.femti-71.no/",
        date: "29.04.2024"
    },
    {
        grantee: "Søndre Sound",
        sum: 30000,
        reason: {
            no: "Søndre Sound planlegger å lansere en DJ-skole for ungdom i alderen 13-20 år i Søndre Nordstrand. Dette vil være et gratis tilbud som vil gi ungdommene muligheten til å lære grunnleggende DJ-ferdigheter og utforske musikkproduksjon på en interaktiv og engasjerende måte. Målet med DJ-skolen er å gi ungdommene en positiv arena hvor de kan utvikle sine musikalske talenter og samtidig bygge et fellesskap med andre ungdommer med lignende interesser. Gjennom støtte fra PAF vil DJ-skolen kunne tilby nødvendig DJ-utstyr og ressurser for å gjøre dette tilbudet tilgjengelig for ungdommer med ulike bakgrunner og interesser.\n",
            en: "Søndre Sound is planning to launch a DJ school for youth aged 13-20 in Søndre Nordstrand. This will be a free program providing young people with the opportunity to learn basic DJ skills and explore music production in an interactive and engaging way. The goal of the DJ school is to provide youth with a positive environment where they can develop their musical talents while building a community with other peers who share similar interests. With support from PAF, the DJ school will be able to offer necessary DJ equipment and resources to make this opportunity accessible to youth from diverse backgrounds and interests.\n"
        },
        href: "https://sondresound.no/",
        date: "29.04.2024"
    },
    {
        grantee: "Ytre Søre Sunnmøre Symjeklubb",
        sum: 60000,
        reason: {
            no: "Ytre Søre Sunnmøre Symjeklubb (YSSS) er en svømmeklubb i kommunene Herøy, Ulstein, Hareid og Sande. YSSS har i flere år samarbeidet med kommunene for å inkludere flyktninger i klubbens aktiviteter. Å lære å svømme er av avgjørende betydning i kystsamfunnene, både for sikkerhet og sosial mestring. Mange av familiene som deltar har begrensede ressurser, og YSSS er i stor grad avhengig av frivillig innsats. Selv om klubben sikret offentlige midler til inkluderingstiltak i 2022 og 2023, mottok de ikke støtte i 2024. YSSS ønsker å fortsette disse tiltakene for å gi unge flyktninger like muligheter til å delta i aktivitetene. Med støtte fra PAF kan klubben tilby svømmekurs til flyktninger, kjøpe utstyr og redusere deltakeravgiften med 50 % for minoritetsfamilier.\n",
            en: "Ytre Søre Sunnmøre Swim Club (YSSS) is a swimming club serving the municipalities of Herøy, Ulstein, Hareid, and Sande. YSSS has collaborated with the municipalities for several years to include refugees in the club's activities. Learning to swim is of crucial importance in coastal communities, both for safety and social integration. Many of the families participating have limited resources, and YSSS relies heavily on volunteer efforts. Although the club secured public funding for inclusion initiatives in 2022 and 2023, they did not receive support in 2024. YSSS aims to continue these initiatives to provide young refugees with equal opportunities to participate in the activities. With support from PAF, the club can offer swimming courses for refugees, purchase equipment, and reduce participation fees by 50% for minority families.\n"
        },
        href: "https://www.ysss.no/",
        date: "29.04.2024"
    },
    {
        grantee: "WAHA",
        sum: 75000,
        reason: {
            no: "\"WAHA\" er et tverrfaglig kunstprosjekt rettet mot barn og unge enslige mindreårige flyktninger og asylsøkere på Trondheim mottakssenter. Prosjektet vil tilby jevnlige aktiviteter for en målgruppe som ofte blir oversett eller ekskludert i det norske samfunnet. Inspirert av ordet \"waha\" fra arabisk og persisk, samt \"inngang, portal, åpning, munn\" for Maori-folket, søker prosjektet å skape et åpent rom for deltakelse og uttrykk. Gjennom kunstneriske metoder og deltakernes behov og erfaringer, utforsker WAHA hvordan aktiviteter og kreativitet kan forbedre livskvaliteten til barn og unge på mottaket. Deltakernes opplevelser og historier inspirerer til å frigjøre energi, lek og skaperglede gjennom kropp og kunst. Med støtte fra PAF vil WAHA skaffe parkourutstyr, frø og dyrkeutstyr, sirkusutstyr og kunstmaterialer.",
            en: "\"WAHA\" is an interdisciplinary art project aimed at children and young unaccompanied minor refugees and asylum seekers at the Trondheim reception center. The project aims to provide regular activities for a group that is often overlooked or excluded in Norwegian society. Inspired by the word \"waha\" from Arabic and Persian, meaning \"garden, oasis, plain,\" and \"entrance, portal, opening, mouth\" for the Maori people, the project seeks to create an open space for participation and expression. Through artistic methods and the participants' own needs and experiences, \"WAHA\" explores how activities and creativity can improve the quality of life for children and young people at the reception center. The experiences and stories of the participants inspire the release of energy, play, and joy through body and art. With support from PAF, \"WAHA\" will acquire parkour equipment, seeds and gardening equipment, circus equipment, and art materials.\n"
        },
        date: "29.04.2024"
    },
    {
        grantee: "Wesley United Football Academy, Inc.",
        sum: 75000,
        reason: {
            no: "Wesley United Football Academy Inc., basert i hovedstaden Monrovia i Liberia, har en visjon om å gi barn og unge i økonomisk vanskeligstilte områder muligheten til å utvikle sine fotballferdigheter og skape en bedre fremtid for seg selv. Akademiet ble grunnlagt i mai 2021 med mål om å bidra til lokalsamfunnet. De tilbyr gratis fotballtrening for ungdom mellom 6 og 19 år og planlegger å utvide til å inkludere et jenteakademi, som vil øke antallet deltakere til over 200. Med støtte fra PAF vil akademiet kunne anskaffe nødvendig utstyr for å oppfylle sine mål.",
            en: "Wesley United Football Academy Inc., located in the capital city of Monrovia in Liberia, aims to provide children and youth in impoverished areas the opportunity to develop their football skills and secure a better future. The academy was established in May 2021 with the intention of giving back to the community. They offer free football training for youth aged 6 to 19 and plan to establish a girls' academy as well, increasing the total number of participants to over 200. With support from PAF, the academy will be able to purchase necessary equipment to achieve its goals."
        },
        href: "https://www.facebook.com/wesleyunitedlr/",
        date: "29.04.2024"
    },
    {
        grantee: "Dråpen i Havet",
        sum: 150000,
        reason: {
            no: "Victoria-senteret i Athen, sammen med seks partnerorganisasjoner, tilbyr språkundervisning, datakurs og jobbrelaterte workshops. Senteret har en gratis kafé, medisinsk hjelp og barneomsorg, og er stadig besøkt med ventelister for språkklasser. Etter opphold i flyktningleirer på øyene, forlater mange leirene og ender opp i Athen, noen som papirløse. Med støtte fra PAF kan Victoria-senteret fortsette sitt verdifulle arbeid for disse flyktningene.\n",
            en: "The Victoria Center in Athens, along with six partner organizations, offers language classes, computer courses, and job-related workshops. The center provides a free café, medical assistance, and childcare services, and is consistently visited with waiting lists for language classes. After staying in refugee camps on the islands, many refugees leave the camps and end up in Athens, some as undocumented individuals. With support from PAF, the Victoria Center can continue its valuable work for these refugees.\n"
        },
        href: "https://www.drapenihavet.no/",
        date: "29.04.2024"
    },
    {
        grantee: "One Family No Borders",
        sum: 165000,
        reason: {
            no: "One Family - No Borders mottar økonomisk støtte til sin skolestipendordning. Prosjektet gir støtte til 32 syriske barn og unge i Adana og Reyhanli, Tyrkia, slik at de kan få muligheten til å gå på skolen i stedet for å bli tvunget ut i barnearbeid. Familiene til disse barna er ekstremt fattige og mottar månedlig økonomisk støtte, mot at barna går på skolen. Med støtte fra PAF, kan One Family - No Borders fortsette å gi disse barna muligheten til å få seg en utdanning.",
            en: "One Family - No Borders receives financial support for its scholarship project. The project provides support to 32 Syrian children and young people in Adana and Reyhanli, Türkiye, so that they can have the opportunity to go to school instead of being forced into child labour. The families of these children are extremely poor and receive monthly financial support, in return for the children going to school. With the support of PAF, One Family - No Borders can continue to give these children the opportunity to get an education."
        },
        href: "https://www.facebook.com/ONEFAMILYNOBORDERS/",
        date: "29.04.2024"
    },
    {
        grantee: "Foreningen Fotballbaner for jenter og gutter i Kobanê, Raqqa og Qamishlo",
        sum: 30000,
        reason: {
            no: "Organisasjonen \"Fotballbaner for jenter og gutter i Kobanê, Raqqa og Qamishlo\" mottar støtte for å bygge en fotballbane i Tabqah. Tabqah, også kjent som Ath-Thawrah på arabisk eller kurdiske, ble frigjort fra IS våren 2017, men led betydelige ødeleggelser. PAF bidrar med 30 000 kr til prosjektet.\n",
            en: "The organization \"Football Fields for Girls and Boys in Kobanê, Raqqa, and Qamishlo\" receives support to build a football field in Tabqah. Tabqah, also known as Ath-Thawrah in Arabic or Kurdish, was liberated from ISIS in the spring of 2017, but suffered significant destruction. PAF is supporting the project with 30,000 kr.\n"
        },
        href: "https://fotballbane.org/",
        date: "29.04.2024"
    },
    {
        grantee: "Northern Lights Aid",
        sum: 80000,
        reason: {
            no: "Northern Lights Aid (NLA) mottar støtte for å opprettholde deres to ukentlige Mor og  Baby-program i Kavala-leiren i Hellas. Mor og baby-programmet er utformet for å gi personer i nød tilgang til nødvendige klær, babyutstyr og hygieneartikler på en verdig måte. Programmet fungerer også som et støttende fellesskap der deltakerne kan få rådgivning, medisinsk hjelp og annen støtte gjennom et pålitelig nettverk.\n\n Støtten fra PAF dekker kostnadene for 12 måneder med viktige hygieneprodukter til 40 familier som bor både i leiren og i lavinntektsboliger i området.\n",
            en: "Northern Lights Aid (NLA) receives support to sustain their bi-weekly Mother and Baby program in the Kavala camp in Greece. The Mother and Baby program is designed to provide people in need with access to essential clothing, baby supplies, and hygiene items in a dignified manner. The program also serves as a platform for individuals to receive assistance (counseling, medical referrals, etc.) through a consistent support network.\n\n The support from PAF covers 12 months of essential hygiene products for 40 families living both in the camp and in low-income housing in the area.\n"
        },
        href: "https://www.northernlightsaid.org/",
        date: "29.04.2024"
    },




    {
        grantee: "Fargespill Trondheim",
        sum: 100000,
        reason: {
            no: "Fargespill Trondheim er et flerkulturelt kunstnerisk prosjekt med mer enn 100 barn og ungdom fra 40 land, inkludert Norge. Forestillingen har basis i musikk, dans, sirkus og barnelek som fargespillaktørene har med seg fra sine land og kulturer, i samspill med norsk musikktradisjon og global ungdomskultur. Alle nyankomne barn og ungdommer som kommer i mottaksklasser i Trondheim får oppleve Fargespill Trondheim. Med tilskudd fra PAF skal barna og ungdommene få stå midt i scenelyset og by på seg selv, sin kultur og historie.",
            en: "Fargespill Trondheim is a multicultural artistic project with more than 100 children and youth from 40 countries, including Norway. The performance displays music, dance, circus and children's play brought from the actors' cultures, in interaction with Norwegian musical traditions and global youth culture. All newly arrived children and youth who come to reception classes in Trondheim get to experience Fargespill Trondheim. With a grant from PAF, the children and youth will be able to take center stage and present themselves, their culture and history."
        },
        href: "https://www.kamfest.no/nb-no/fargespill",
        date: "20.04.2023"
    },
    {
        grantee: "Lambertseter Kings",
        sum: 35000,
        reason: {
            no: "Lambertseter Kings er et fotballag sammensatt på eget initiativ av Haidar (19) sommeren 2021, med et tjuetalls andre ungdommer med syrisk bakgrunn som liker å spille fotball. Dette var ungdom som enten ikke har kommet inn i organisert idrett her i Norge eller har falt ut av organisert idrett av ulike grunner. Prosjektet er nå et samarbeid med Lambertseter fotballklubb og Kinginstituttet. I sommer får de arrangere en sommercamp for fotballaget, med mål om å bygge team og relasjoner med midler fra PAF.",
            en: "Lambertseter Kings is a football team put together (on initiative) by Haidar (19) in the summer of 2021, with about twenty other young Syrians who like to play football. They are young people that haven’t entered organized sports in Norway yet or have dropped out for various reasons. This summer, with funds from PAF, they get to organize a summer camp for the team, with the aim of building teams and relationships."
        },
        href: "",
        date: "20.04.2023"
    },
    {
        grantee: "VårtNabolag",
        sum: 100000,
        reason: {
            no: "VårtNabolag er en festival som arrangeres på, og i samarbeid med, mottak, der publikum inviteres til en dag med konserter, mat og underholdning. Et av hovedmålene til VårtNabolag er å skape en inkluderende møteplass der beboere på mottak i Norge og nabolaget kan møtes gjennom musikk, mat, kultur og frivillighet, for å ønske mennesker på flukt velkommen til nabolaget. Slik bekjemper de fordommer og fremmer kunnskap om - og øker interessen for - ulike lands kulturer. Med midler fra PAF får VårtNabolag skape en kulturell møteplass for å bekjempe fordommer og bidra til integrering. Festivalen arrangeres av Oslo World.",
            en: "VårtNabolag, which means OurNeighbourhood, is a festival organized at- and in collaboration with an asylum reception center. The public is invited to a day of concerts, food and entertainment. One of the main goals of VårtNabolag is to create an inclusive meeting place where residents in reception and the neighborhood can meet through music, food, culture and volunteering – to welcome refugees to the neighborhood. It is a way to combat prejudices and promote knowledge of - and increase interest in different countries' cultures. With funds from PAF, VårtNabolag can create this important meeting place. The festival is organized by Oslo World."
        },
        href: "https://www.osloworld.no/vartnabolag",
        date: "20.04.2023"
    },
    {
        grantee: "Ringsaker Røde Kors",
        sum: 48500,
        reason: {
            no: "Ringsaker Røde Kors mottar støtte fra PAF for å gjennomføre to prosjekter i 2023. Hver uke har de norsktrening for bosatte innvandrere og andre fremmedspråklige. I tillegg mottar de støtte for å arrangere Kafé Sol for kvinner hver måned, med mål om å knytte vennskap og skaffe et større nettverk. Med midler for PAF kan Ringsaker Røde Kors videreføre initiativene.",
            en: "Ringsaker Red Cross receives support from PAF to carry out two projects in 2023. Every week they have Norwegian language training for resident immigrants and other foreign speakers. In addition to this, they receive support to organize Kafé Sol for women every month, with the aim of forming friendships and creating a larger network for the visitors. With funds from PAF, Ringsaker Red Cross can continue their initiatives."
        },
        href: "https://www.rodekors.no/lokalforeninger/hedmark/ringsaker/",
        date: "20.04.2023"
    },
    {
        grantee: "Mortensrud-Aker Sportsklubb",
        sum: 20000,
        reason: {
            no: "Mortensrud-Aker Sportsklubb er et idrettslag i Bydel Søndre Nordstrand som driver både organisert idrett, lavterskel tilbud for barn og unge og åpen hall for hele bydelens ungdommer. I tillegg tilbyr de ferieaktiviteter for barn og unge som ikke har mulighet til å betale for aktiviteter i feriene eller reise på dyre ferier. For å inkludere sårbare ungdommer som faller ut av idretten av ulike grunner, arrangerer de også utflukter for dem. Disse utfluktene dekkes ikke av andre midler idrettslaget mottar, med midler fra PAF får barna i målgruppen mulighet til å delta på meningsfylte utflukter med klubben og oppleve det fantastiske Norge som ellers ikke hadde vært mulig.",
            en: "Mortensrud-Aker Sports Club is a sports club in the district of Søndre Nordstrand in Oslo. They offer organized sports and create holiday activities for children and youth that are unable to pay for activities during the holidays. In order to make those activities more exciting and inclusive for the target group, they are dependent on arranging excursions for participants. These excursions are not covered by other funds, but with a contribution from PAF, the children in the sports club will have the opportunity to participate in fun holiday activities in 2023 as well."
        },
        href: "https://mask.no/",
        date: "20.04.2023"
    },
    {
        grantee: "Moriabevegelsen",
        sum: 55000,
        reason: {
            no: "Forholdene i den nye Morialeiren Kara Tepe utenfor Mytilini på Lesvos i Hellas er under enhver kritikk. Flere barn og ungdom som har opplevd krig og terror blir retraumatisert av forholdene i leiren og helsehjelpen er mangelfull. Det bor for tiden i overkant av 2.000 mennesker i leiren, og tallet øker frem mot sommeren. Fritidstilbudet for barna og ungdommene er tilnærmet ikke-eksisterende. Det er svært vanskelig å komme inn i leiren for utenforstående, både hva gjelder internasjonale hjelpeorganisasjoner og kulturarbeidere. Med midler fra PAF kan Moriabevegelsen ta med innbyggerne ut av leiren og gi dem noen etterlengtede timer med underholdning og glede.",
            en: "The conditions in the new Moria camp Kara Tepe outside Mytilini on Lesvos, Greece, are critical. Children and youth who have experienced war and terror are retraumatized by the conditions in the camp and health care is inadequate. There are currently more than 2,000 people living in the camp and the number increases. Leisure facilities for children and youth are virtually non-existent. It is very difficult for outsiders to enter the camp, both in terms of international aid organizations and cultural workers. With funds from PAF, the Moria movement can take the residents out of the camp and give them a few long-awaited hours of entertainment and joy."
        },
        href: "https://evakuermoria.org/",
        date: "20.04.2023"
    },
    {
        grantee: "One Family – No Borders",
        sum: 245000,
        reason: {
            no: "One Family – No Borders er en familiedrevet hjelpeorganisasjon som har drevet med frivillig hjelpearbeid i alle ferier, med mer, siden 2015. Familien på tre får midler av PAF til to prosjekter. Etter at Tyrkia og Syria ble rammet av jordskjelv tidligere i år, ønsker familien å hjelpe rammede familier, og mottar støtte på 100 000 kroner til dette. I tillegg får familien støtte til å videreføre skolestipend til en rekke syriske barn, for å gi dem mulighet til å gå på skole fremfor å jobbe. Til dette arbeidet mottar de 145 000 kroner.",
            en: "One Family – No Borders is a family-run aid organization that has been involved in voluntary aid work since 2015. The family of three receives funds from PAF for two projects. In the aftermath of Turkey and Syria being hit by earthquakes earlier this year, the family wants to help affected families and receives NOK 100,000 to make it happen. In addition to this, the family receives support to pass on school scholarships to a number of Syrian children, so they get the opportunity to go to school rather than work. To do this, they receive NOK 145,000."
        },
        href: "https://www.facebook.com/ONEFAMILYNOBORDERS/",
        date: "20.04.2023"
    },

    {
        grantee: "Abdisalam Ibrahim Football School",
        sum: 170000,
        reason: {
            no: "Abdisalam Ibrahim Football School ligger i Dinsoor, som er et av verdens fattigste områder. Skolen har vært i drift i et år, og gir barn og ungdommer i fattige områder muligheten til å utvikle sine fotballferdigheter og få en bedre fremtid. Skolen tilbyr gratis trening til ungdommer mellom 4-16 år og noen ganger gratis mat til barna. Nå ønsker de å utvide tilbudet, med blant annet en skole for å kunne gi barna bedre utdanning. Tilbudet startet med omtrent 20 barn, men har nå over 350 barn og ungdommer som er innom tilbudet, samt veldig mange på venteliste. Med støtte fra PAF får Abdisalam Ibrahim Football School bygge en skole for barna, utvide med en 11 mannsbane av gress, brønn, parsellhage, vanningssystem og til drift og vedlikehold av skolen.",
            en: "Abdisalam Ibrahim Football School is located in Dinsoor, one of the poorest areas in the world. The school has been in operation for a year, and gives children and youth in poor areas the opportunity to develop their football skills and improve their possibilities for a better future. The school offers free training for youth between the ages of 4-16, and sometimes free food for the children. Now they want to expand their operation to include a school that can secure the children a better education. It started with approximately 20 children, but now over 350 children and youth are enrolled in the program, with many more on the waiting list. With support from PAF, Abdisalam Ibrahim Football School gets to build a school for the children, expand with a new football field, a well, allotment garden, irrigation system and maintenance of the school."
        },
        href: "https://www.instagram.com/abdisalamibrahimfootballschool/",
        date: "20.04.2023"
    },

    {
        grantee: "Greater Africa Foundation (GAF)",
        sum: 30000,
        reason: {
            no: "Greater Africa Foundation er en lokalt opprettet og drevet organisasjon i Uganda som gir opplæring innen ulike yrker og bistår med å starte småskala virksomheter. I tillegg gir de et tilbud til barna i familiene som ikke har råd til skolegang. Med tilskudd fra PAF får de bygget ferdig et skolebygg, som kan gi plass til flere elever.",
            en: "The Greater Africa Foundation is a locally established- and run organization in Uganda, that provides training in various professions and assistance for small-scale businesses. IThey also provide an program for children in families who cannot afford schooling. With a grant from PAF, they are able to complete the construction of a school building, which can accommodate more students."
        },
        href: "https://www.greaterafricafoundation.com/",
        date: "20.04.2023"
    },

    {
        grantee: "Nordic Black Xpress",
        sum: 100000,
        reason: {
            no: "Nordic Black Xpress NBX er Nordic Black Theatres egne teaterskole og teaterutviklingsprosjekt. Skolen har et fokus på den kunstneriske kompetansen som mangfold og ‘transkulturell’ utveksling gir, der skuespillertalentene har flere kulturelle ståsteder enn norsk. Xpressen består idag av 11 unge teaterstudenter, og med midler fra PAF kan de spille forestillinger gratis for barn i Botsparken på Grønland.",
            en: "Nordic Black Xpress NBX is Nordic Black Theatre's own theater school and theater development project. The school focuses on the artistic competence that diversity and 'transcultural' exchange provides. Today, the Xpress consists of 11 young theater students, and with funding from PAF they can play free shows for children in Botsparken in the Grønland district in Oslo."
        },
        href: "https://nordicblacktheatre.no/about/nordic-black-xpress/",
        date: "20.04.2023"
    },

    {
        grantee: "Skeiv Verden",
        sum: 50000,
        reason: {
            no: "Skeiv Kafé er en frivillig drevet rusfri, månedlig møteplass med gratis bevertning, åpne temakvelder og sosialt samvær. Et slikt fordomsfritt møtested er verdifullt for LHBTIQ+ personer med innvandrerbakgrunn, da mange har mistet familie og nettverk ved å komme ut som skeive. Andre igjen er ikke åpne og har derfor ikke andre arenaer der de kan være sitt fulle hele selv. Skeiv Kafé blir for dem et “fristed” der de kan være seg selv i trygge rammer, og skaper også et møtepunkt mellom relativt nyankomne flyktninger og innvandrere, og det norske samfunnet. Med midler fra PAF kan de fortsette å bidra til trygge møteplasser for LHBTIQ+ personer i Bergen og omegn, Stavanger og Hamar.",
            en: "Skeiv Café is a volunteer-run drug free, monthly meeting place with free entertainment, open themed meetings and social gatherings. Such an open minded meeting place is valuable for the LGBTIQ+ community  with an immigrant background, as many have lost family and networks by coming out as queer. Others are not open at all, and don’t have other arenas where they can be themselves. Skeiv Café becomes a \"sanctuary\" – a safe environment and a meeting place for recently arrived refugees and immigrants, and the Norwegian society. With funds from PAF, they can continue to create safe meeting place for LGBTIQ+ people in Bergen and its surroundings, as well as Stavanger and Hamar."
        },
        href: "https://www.skeivverden.no/meetups",
        date: "20.04.2023"
    },


    {
        grantee: "Ung Bjørndal",
        sum: 70000,
        reason: {
            no: "Ung Bjørndal er en del av Redd Barnas satsning på inkludering og gode levekår for alle barn, " +
                "i samarbeid med bydel Søndre Nordstrand. Kjernen i programmet er samskaping gjennom ungdomsforum, " +
                "hvor lokal ungdom selv bestemmer temaer og aktiviteter. Grunntanken i ungdomsforumet er at man skal " +
                "styrke deltakernes opplevelse av og mulighet til å påvirke eget liv, nærmiljø og samfunn som helhet, " +
                "og skape åpne møteplasser, temakvelder, workshops, kafeer, kampanjer og lokal frivillighet.",
            en: "Ung Bjørndal is part of Save the Children's commitment to inclusion and better living conditions for " +
                "all children, in collaboration with the Søndre Nordstrand district in Oslo."
        },
        href: "https://www.ungbjorndal.no/",
        date: "01.03.2022"
    },
    {
        grantee: "International Welfare",
        sum: 60000,
        reason: {
            no: "International Welfare jobber for å støtte sårbare familier i Trøndelag ved å tilby aktiviteter " +
                "for barn og unge, og organiserer turer med barn og foresatte til inn- og utland. Aktivitetene " +
                "er åpne for alle, men prioriterer sårbare familier. Med midlene fra PAF ønsker International " +
                "Welfare å organisere turer i skoleferien med familiene fra Steinkjer asylmottak.",
            en: "International Welfare works to support vulnerable families in Trøndelag county, especially by" +
                " offering activities for children and youth. ",
        },
        href: "",
        date: "01.03.2022"
    },
    {
        grantee: "Stiftelsen JoinUs",
        sum: 100000,
        reason: {
            no: "Stiftelsen JoinUs tilbyr møteplasser for aktivitet, skaperglede og mestringsfølelse. Hensikten er å " +
                "hjelpe mennesker med å styrke sin kunnskap om språk, miljø og sosiale forhold gjennom organiserte " +
                "fysiske og kreative aktiviteter og derved legge til rette for en enklere vei inn i samfunnet. Med " +
                "midlene fra PAF ønsker JoinUs å arrangere svømmekurs for barn og unge i Molde.",
            en: "The JoinUs Foundation offers activities with the purpose of making integration easier for " +
                "everyone, regardless of background and starting point.",
        },
        href: "https://www.stiftelsenjoinus.no/",
        date: "01.03.2022"
    },
    {
        grantee: "Way Forward",
        sum: 170000,
        reason: {
            no: "Way Forward ønsker å realisere prosjektet “Aktiv i tet”. Prosjektet vil ha fokus på jenters " +
                "integrering gjennom å være aktiv. Prosjektet vil gå ut på å opprette en jentegruppe, åpen for" +
                " alle jenter mellom 8-15 år, styrt av to kvinnelige aktivitetsledere. Prosjektet skal skape en" +
                " møteplass for jenter på Slettebakken i Bergen, uansett kulturell bakgrunn, gjennom ulike " +
                "aktiviteter etter skoletid.",
            en: "Way Forward wishes to create after school activities for 8 to 15 year old girls in the city of " +
                "Bergen, regardless of cultural background.",
        },
        href: "https://www.wayforward.no/",
        date: "01.03.2022"
    },
    {
        grantee: "Kvinnenettverket Noor",
        sum: 100000,
        reason: {
            no: "Kvinnenettverket NOOR en frivillig organisasjon i Nordland. Hovedmålet med organisasjonen er å jobbe" +
                " for å styrke kvinner i det norske samfunnet med fokus på kvinner med etnisk minoritetsbakgrunn. " +
                "NOOR har vært en aktiv aktør og en viktig stemme i integreringsarbeid både på lokalt, regionalt " +
                "og nasjonalt nivå. Organisasjonen ønsker å realisere flere prosjekter med midlene fra PAF: 1) " +
                " Prosjektet \"Helseparty\" - Formidling av informasjon og kunnskap om helse 2) " +
                "Prosjektet \"Our story is your story\": Integrering, selvutvikling og samtilhørighet gjennom " +
                "frivillig engasjement, 3) Sykkelopplæring og svømmekurs, samt andre tiltak for å skape " +
                "flere møteplasser for minoritetskvinner i Nordland.",
            en: "Kvinnenettverket NOOR is a voluntary organization in Nordland county. " +
                "The main goal of the organization is to empower women with ethnic minority " +
                "backgrounds in Norwegian society. ",
        },
        href: "https://www.k-noor.no/",
        date: "01.03.2022"
    },
    {
        grantee: "Dråpen i Havet",
        sum: 90000,
        reason: {
            no: "Dråpen i Havet er en humanitær non-profit organisasjon med formål om å yte støtte til " +
                "mennesker på flukt, samt å spre informasjon om deres livssituasjon. Dråpen i Havet er per i dag" +
                " tilstede på fire ulike lokasjoner i Hellas, samt to steder i Bosnia Herzegovina der de jobber i," +
                " og utenfor, flyktningleirene. Siden oppstarten i 2015 har de bidratt med distribusjoner av mat, " +
                "klær og andre nødvendige artikler og arrangerer en rekke aktiviteter som gir mennesker på flukt " +
                "et pusterom i en ellers vanskelig hverdag.",
            en: "Dråpen i Havet is a humanitarian non-profit organization with the purpose of " +
                "providing support to refugees, as well as spreading awareness about their life situation.",
        },
        href: "https://www.drapenihavet.no/",
        date: "01.03.2022"
    },
    {
        grantee: "Verdige Smil",
        sum: 363000,
        reason: {
            no: "Verdige Smil er en frivillig medlemsbasert, ideell organisasjon som jobber for å bidra til " +
                "at barns grunnleggende rett til å leve og rett til å utvikle seg blir oppfylt, på både" +
                " nasjonalt og internasjonalt plan. Med midlene fra PAF ønsker Verdige Smil å bygge to " +
                "skoleklasser, et aktivitetsrom og en lekeplass i Sierra Leone. ",
            en: "Verdige Smil is a voluntary member-based, non-profit organization that strive to ensure that " +
                "children's basic right to live and their right to develop are fulfilled, both nationally" +
                " and internationally.",
        },
        href: "https://verdigesmil.no/",
        date: "01.03.2022"
    },
    {
        grantee: "The Flying Seagull Project ",
        sum: 20000,
        reason: {
            no: "The Flying Seagull Project jobber for å spre lek og latter blant barn i utsatte situasjoner i en " +
                "rekke land. Siden 2015 har prosjektet i hovedsak jobbet med folk på flukt i Europa og Libanon, " +
                "og arbeidet i lengre perioder på Lesvos. The Flying Seagulls er en gjeng med frivillige som " +
                "reiser til flyktningleirer der de underholder og aktiviserer barn, blant annet gjennom sirkus og" +
                " klovning. De siste 12 årene har The Flying Seagull Project besøkt over 275.000 mennesker, og" +
                " organisasjonen vil bruke midlene fra PAF til å videreføre og bygge ut initiativet.",
            en: "The Flying Seagull Project works to spread music and joy among children in " +
                "vulnerable situations in a number of countries.",
        },
        href: "https://www.theflyingseagullproject.com/no",
        date: "01.03.2022"
    },
    {
        grantee: "Foreningen Fotballbaner for jenter og gutter i Kobanê og Raqqa",
        sum: 27000,
        reason: {
            no: "Foreningen Fotballbaner for jenter og gutter i Kobanê og Raqqa bygger fotballbaner for " +
                "masseidretten. Foreningen samarbeider tett med lokale myndigheter om planlegging og utførelse. " +
                "Alle midler som foreningen samler inn går uavkortet til dette arbeidet. To baner ble bygget i " +
                "Kobanê i 2019. Tildelingen fra PAF vil være et viktig bidrag til å bygge to kunstgressbaner i" +
                " Raqqa i 2022. Raqqa var kjent som IS sin hovedstad i Syria fram til 2017. Som følge av krigen " +
                "er mye av infrastrukturen i byen ødelagt og har siden 2018 vært i en gjenoppbyggingsfase.",
            en: "Foreningen Fotballbaner for jenter og gutter i Kobanê og Raqqa builds football courts for " +
                "mass sports in the Syrian cities Kobanê and Raqqa. ",
        },
        href: "http://fotballbane.org",
        date: "01.03.2022"
    },


];



