import React from "react";
import {default as M} from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Text from "./Text";
import {COLORS} from "../constants";


const Modal = ({show, showHeader = true, showFooter = true, closeFn, title, children, rtl, ...rest}) => (
    <M
        show={show}
        {...rest}
    >
        {showHeader ?
            <M.Header style={{borderBottomColor: COLORS.primary, display: "unset"}} className={rtl ? "" : "text-right"}>
                <M.Title className={rtl ? "" : "text-right"}>{title}</M.Title>
            </M.Header>
            :
            <></>
        }

        <M.Body className={rtl ? "" : "text-right"}>
            <Text className={rtl ? "" : "text-right"}>{children}</Text>
        </M.Body>

        {showFooter ?
            <M.Footer style={{borderTopColor: COLORS.primary}}>
                <Button variant="secondary" onClick={closeFn}>Close</Button>
            </M.Footer>
            :
            <></>
        }
    </M>
);

export default Modal;
