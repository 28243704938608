// Tests example quotes from https://github.com/alexnm/re-ducks
//
const SET_ACTIVE_ALBUM = "app/displayAlbum/SET_ACTIVE_ALBUM";
const SET_ACTIVE_TRACKLIST = "app/displayAlbum/SET_ACTIVE_TRACKLIST";
const SET_MODAL_ACTIVE = "app/displayAlbum/SET_ACTIVE_MODAL";
const SET_MODAL_COVERART = "app/displayAlbum/SET_MODAL_COVERART";


export {
    SET_ACTIVE_ALBUM, SET_ACTIVE_TRACKLIST, SET_MODAL_ACTIVE, SET_MODAL_COVERART
};
