import {Fragment} from "react";
import React from "react";
import Text from "./Text";
import i18n from "../i18n";
import CatalogGrid from "./CatalogGrid";
import FadeIn from "./FadeIn";
import Button from "react-bootstrap/Button";
import XIcon from "./Icons/XIcon";
import Modal from "./Modal";
import {useSelector} from "react-redux";
import {useDispatch} from "react-redux";
import {setModalActive} from "../../state/ducks/displayAlbum/operations";
import {setModalCoverArtSrc} from "../../state/ducks/displayAlbum/operations";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import ImageFadeIn from "react-image-fade-in";
import {TrackList} from "./TrackList";
import {ALBUMS} from "../constants";
import {useMediaQuery} from "react-responsive";
import ProgressiveImage from "react-progressive-image-loading";


export function CatalogGridView({ltr}) {
    const albumState = useSelector(state => state.displayAlbum);
    const originalALbum = {...albumState.displayAlbum};
    const dispatch = useDispatch();

    const isMobile = useMediaQuery({query: "(max-width: 768px)"});

    const loadHeisannImagesInBackground = () => {
        // pre-load Heisann Montebello-lowres
        ALBUMS[2].tracks.forEach((album) => {
            new Image().src = album.coverArtSrc;
        });
    };

    const loadImagesInBackground = () => {
        // pre-load high res images
        ALBUMS.forEach((album) => {
            new Image().src = album.coverArtHighresSrc;
        });
    };

    if (!isMobile) {
        //setTimeout(loadImagesInBackground, 3000)
        setTimeout(loadHeisannImagesInBackground, 10000);
    }


    const onMouseOverFn = (album) => {
        if (album !== undefined) {
            if (album.coverArtSrc) {
                dispatch(setModalCoverArtSrc(album));
            }
        }
    };

    return (
        <Fragment>
            <div className={!ltr ? "text-right album-list-title" : "album-list-title"}>
                <FadeIn fadeTime={400} order={0}>
                    <Text style={{textTransform: "uppercase"}}
                          fontSize='1.5em'>{i18n.t("album.label")}
                    </Text>
                </FadeIn>
            </div>
            <CatalogGrid ltr={ltr}/>


            <Modal showHeader={false} showFooter={false} dialogClassName="modal-90w modal-fullscreen"
                   backdropClassName="modal-fullscreen-backdrop"
                   rtl={false} closeFn={() => {
                setModalActive(false);
            }} show={albumState.modalActive} title={i18n.t("contract.title")}>
                <Button size='lg' variant='link' onClick={() => {
                    dispatch(setModalActive(false));
                }}>
                    <Text fontSize='2vw'><XIcon/></Text>
                </Button>
                <Row>
                    <Col sm={6}>

                        {
                            albumState.displayAlbum.useImageFade ?

                                <ImageFadeIn

                                    //key={albumState.modalCoverArtSrc}
                                    src={albumState.modalCoverArtHighresSrc}
                                    style={{
                                        width: "100%",
                                        cursor: "pointer",
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                    }}
                                    onClick={() => {
                                        dispatch(setModalActive(false));
                                    }}/>

                                :
                                <FadeIn fadeTime={400} order={2}>
                                    <ProgressiveImage
                                        //key={albumState.modalCoverArtSrc}
                                        preview={albumState.modalCoverArtSrc}
                                        src={albumState.modalCoverArtHighresSrc}
                                        transitionTime={1000}
                                        style={{width: "100%", cursor: "pointer"}}
                                        transitionFunction="ease"
                                        render={(src, style) => <img key={albumState.displayAlbum.id} src={src}
                                                                     style={{width: "100%", cursor: "pointer"}}
                                                                     onClick={() => {
                                                                         dispatch(setModalActive(false));
                                                                     }}/>}
                                    />
                                </FadeIn>

                        }

                    </Col>
                    <Col sm={6} style={{paddingTop: "1.1vw", paddingLeft: "3vw", paddingRight: "3vw",}}
                         className='text-left'>

                        <Text onMouseEnter={() => {
                            dispatch(setModalCoverArtSrc(originalALbum));
                        }} style={{paddingBottom: "1vw"}} fontSize={"2vw"}>{albumState.displayAlbum.title}</Text>
                        <div style={{paddingTop: "1vw", paddingRight: "6vw"}}>
                            <TrackList tracklist={albumState.activeTrackList} onMouseOverFn={onMouseOverFn}
                                       className={"modal-tracklist-track-label"}
                                       containerClassName={"modal-tracklist-track-container"}/>
                        </div>
                    </Col>
                </Row>

            </Modal>


        </Fragment>
    );
}
