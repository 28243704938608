import React from "react";
import {Fragment} from "react";
import i18n from "../i18n";
import FadeIn from "./FadeIn";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import ColoredLine from "./ColoredLine";
import {ViewHeader} from "./ViewHeader";
import Text from "./Text";
import Button from "react-bootstrap/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPaperPlane} from "@fortawesome/free-solid-svg-icons";

import {sendTips} from "../../state/ducks/contactForm/operations";
import {Formik} from "formik";
import {useDispatch} from "react-redux";
import Spinner from "react-bootstrap/Spinner";


const RegisterForm = () => {
    const dispatch = useDispatch();


    const cssClassNameLabel = "contact-form-label";
    const cssClassNameButton = "contact-form-submit";


    return (
        <div>
            <Formik
                initialValues={{name: "", telephoneNumber: "", email: "", message: ""}}

                validate={values => {
                    const errors = {};
                    if (!values.name) {
                        errors.name = <Text size="sm" variant="warning">{i18n.t("tips.name.error")}</Text>;
                    }

                    if (!values.email) {
                        errors.email = <Text size="sm" variant="warning">{i18n.t("tips.email.error")}</Text>;
                    }


                    if (!values.message) {
                        errors.message = <Text size="sm" variant="warning">{i18n.t("tips.text.error")}</Text>;
                    }

                    if (!values.telephoneNumber) {
                        errors.telephoneNumber = <Text size="sm" variant="warning">{i18n.t("tips.number.error")}</Text>;
                    }

                    return errors;
                }}

                onSubmit={(values, {setSubmitting, setStatus}) => {
                    setSubmitting(true);
                    sendTips(values, setStatus, setSubmitting, undefined, undefined);
                }}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      status
                      /* and other goodies */
                  }) => (
                    <>{!!status ?
                        <FadeIn fadeTime={600}><Text fontSize={'1.2em'} style={{opacity: 0.8}}>{i18n.t("tips.thankyou.text")}<br/><br/>{i18n.t("tips.thankyou.sign")}</Text></FadeIn> :
                        <Form onSubmit={handleSubmit}>

                            <Form.Group controlId="formFirstname">
                                <Form.Label
                                    className={cssClassNameLabel}><Text>{i18n.t("tips.name.label")}</Text></Form.Label>
                                <Form.Control
                                    type="text"
                                    name="name"
                                    placeholder={i18n.t("tips.name.placeholder")}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.name}
                                    disabled={isSubmitting || status}
                                />
                            </Form.Group>
                            {errors.name && touched.name && errors.name}

                            <Form.Group controlId="formEmail">
                                <Form.Label
                                    className={cssClassNameLabel}><Text>{i18n.t("tips.email.label")}</Text></Form.Label>
                                <Form.Control
                                    type="text"
                                    name="email"
                                    placeholder={i18n.t("tips.email.placeholder")}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.email}
                                    disabled={isSubmitting || status}
                                />
                            </Form.Group>
                            {errors.email && touched.email && errors.email}

                            <Form.Group controlId="formPhoneNumber">
                                <Form.Label
                                    className={cssClassNameLabel}><Text>{i18n.t("tips.number.label")}</Text></Form.Label>
                                <Form.Control
                                    type="text"
                                    name="telephoneNumber"
                                    placeholder={i18n.t("tips.number.placeholder")}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.telephoneNumber}
                                    disabled={isSubmitting || status}
                                />
                            </Form.Group>
                            {errors.telephoneNumber && touched.telephoneNumber && errors.telephoneNumber}

                            <Form.Group controlId="formMessage">
                                <Form.Label
                                    className={cssClassNameLabel}><Text>{i18n.t("tips.text.label")}</Text></Form.Label>
                                <Form.Control
                                    as="textarea" rows="3"
                                    name="message"
                                    onChange={handleChange}
                                    placeholder={i18n.t("tips.text.placeholder")}
                                    onBlur={handleBlur}
                                    value={values.message}
                                    disabled={isSubmitting || status}
                                />
                            </Form.Group>
                            {errors.message && touched.message && errors.message}
                            <br />
                            <Button
                                variant="primary"
                                disabled={isSubmitting}
                                className={cssClassNameButton}
                                type="submit">
                                {isSubmitting ? <Spinner
                                    variant="light"
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                /> : ""}
                                {isSubmitting || status ?
                                    i18n.t("tips.submitting.label")
                                    :
                                    <Fragment>{i18n.t("tips.submit.label")} <FontAwesomeIcon
                                        icon={faPaperPlane}/></Fragment>
                                }
                            </Button>
                        </Form>}</>
                )}
            </Formik>
        </div>);
};

const Contact = () => {

    const cssClassNameLabel = "contact-form-label";
    const cssClassNameButton = "contact-form-submit";

    return (

        <div className='faq-container'>
            <FadeIn fadeTime={400} order={0}>
                <ViewHeader title={i18n.t("tips.label")}/>
            </FadeIn>
            <Row>
                <Col xs={12} sm={8} className=''
                     style={{paddingLeft: "1.5vw", paddingRight: "1.5vw", paddingTop: "2vw", paddingBottom: "2vw",}}>
                    <FadeIn fadeTime={400} order={1}>
                        {/*<Form>
                            <Form.Group controlId="contactForm.Name">
                                <Form.Label><Text
                                    className={cssClassNameLabel}>{i18n.t("tips.name.label")}</Text></Form.Label>
                                <Form.Control type="text" placeholder={i18n.t("tips.name.placeholder")}/>
                            </Form.Group>


                            <Form.Group controlId="contactForm.Email">
                                <Form.Label><Text
                                    className={cssClassNameLabel}>{i18n.t("tips.email.label")}</Text></Form.Label>
                                <Form.Control type="email" placeholder={i18n.t("tips.email.placeholder")}/>
                            </Form.Group>

                            <Form.Group controlId="contactForm.Number">
                                <Form.Label><Text
                                    className={cssClassNameLabel}>{i18n.t("tips.number.label")}</Text></Form.Label>
                                <Form.Control type="number" placeholder={i18n.t("tips.number.placeholder")}/>
                            </Form.Group>

                            <Form.Group controlId="contactForm.Text">
                                <Form.Label><Text
                                    className={cssClassNameLabel}>{i18n.t("tips.text.label")}</Text></Form.Label>
                                <Form.Control as="textarea" rows="3" placeholder={i18n.t("tips.text.placeholder")}/>
                            </Form.Group>

                            <Button className={cssClassNameButton} type="submit">
                                {i18n.t("tips.submit.label")} <FontAwesomeIcon icon={faPaperPlane}/>
                            </Button>
                        </Form>*/}
                        <RegisterForm/>

                    </FadeIn>
                </Col>
            </Row>
            <FadeIn fadeTime={400} order={2}>
                <ColoredLine/>
            </FadeIn>

        </div>

    );
};

export default Contact;
