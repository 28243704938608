import ImageFadeIn from "react-image-fade-in";
import React from "react";
import {useState} from "react";
import Modal from "./Modal";
import i18n from "../i18n";
import Button from "react-bootstrap/Button";
import Text from "./Text";
import XIcon from "./Icons/XIcon";
import {useMediaQuery} from "react-responsive";
import i18next from "i18next";
import ProgressiveImage from "react-progressive-image-loading";
import {setActivePage_exitCurrent} from "../../state/ducks/showPage/operations";
import {setActivePage_grants} from "../../state/ducks/showPage/operations";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";



export function Contract({contentRef}) {
    const dispatch = useDispatch();

    const [showContractFullscreen, setContractFullscreen] = useState(false);
    const isMobile = useMediaQuery({query: "(max-width: 767px)"});

    const onclickFn = () => {
        if (showContractFullscreen || isMobile) {
            setContractFullscreen(false);
        } else {
            setContractFullscreen(!isMobile);
        }
    };

    const exitPage = () => {
        dispatch(setActivePage_exitCurrent());
    };

    const showGrants = () => {
        dispatch(setActivePage_grants());
    }

    let clickProps = {};

    if (!isMobile) {
        clickProps = {onClick: onclickFn};
    }

    const lang = i18next.language === "en" ? "en" : "no";

    const contractSrcUrl = "/contracts/kontrakt." + lang + ".1000.jpg";
    const contractSrcUrlThumb = "/contracts/kontrakt." + lang + ".100.jpg";
    const contractSrcUrlHighres = "/contracts/kontrakt." + lang + ".2500.jpg";

    let history = useNavigate();


    const scrollToContent = () => {
        if (contentRef && contentRef.current ) {
            contentRef.current.scrollIntoView({behavior: "smooth"})
        }
    }

    return <div>
        {isMobile && <Button size="sm" variant={"link"} className='language-picker' style={{ paddingBottom: '1rem'}}
                             onClick={() => {
                                 history('/grants')
                                 scrollToContent()
                             }}
        >
            <Text className=" language-picker-text" style={{textTransform: "uppercase",  fontSize: '1.2em'}}>
                {i18n.t("grants.firstlabel")}
            </Text>
        </Button> }
        <ProgressiveImage
            key={contractSrcUrl}
            preview={contractSrcUrlThumb}
            src={contractSrcUrl}
            transitionTime={1000}
            style={{width: "100%", cursor: "pointer"}}
            transitionFunction="ease"
            render={(src, style) => <ImageFadeIn
                src={src}
                className='contract-image'
                style={{width: "100%", transition: "all 1s ease", cursor: "pointer", ...style}}
                aria-label={"Contract image"}
                alt={"Contract text"}
                {...clickProps}
            />}
        />


        {/*        <ImageFadeIn
            src={contractSrcUrl}
            className='contract-image'
            style={{width: "100%", transition: "all 1s ease", cursor: "pointer"}}
            aria-label={"Contract image"}
            alt={"Contract text"}
            {...clickProps}
        />*/}
        <Modal showHeader={false} showFooter={false} dialogClassName="modal-90w modal-fullscreen"
               rtl={false} closeFn={() => {
            setContractFullscreen(false);
        }} show={showContractFullscreen} title={i18n.t("contract.title")}>
            <Button size='lg' variant='link' onClick={() => {
                setContractFullscreen(false);
            }}>
                <Text fontSize='2vw'><XIcon/></Text>
            </Button>
            {/*<Image  style={{width: "100%", cursor: "pointer"}} src={contractSrcUrl}/>*/}
            <div style={{minHeight: "30vw"}}>
                <ProgressiveImage
                    //key={albumState.modalCoverArtSrc}
                    preview={contractSrcUrlThumb}
                    src={contractSrcUrlHighres}
                    transitionTime={500}
                    style={{width: "100%", cursor: "pointer"}}
                    transitionFunction="ease"
                    render={(src, style) => <ImageFadeIn
                        src={src}
                        className='contract-image'
                        style={{width: "100%", transition: "all 1s ease", cursor: "pointer", ...style}}
                        aria-label={"Contract image"}
                        alt={"Contract text"}
                        onClick={() => {
                            setContractFullscreen(false);
                        }}
                    />}
                />
            </div>

            <Button size='lg' variant='link' onClick={() => {
                setContractFullscreen(false);
            }}>
                <Text fontSize='2vw'><XIcon/></Text>
            </Button>
        </Modal>
    </div>;
}

export default Contract;
