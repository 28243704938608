import {SET_ACTIVE_PAGE} from "./types";
import {produce} from "immer";


const initialState = {
    activePage: "catalog"
};

export default function displayAlbumReducer(state = initialState, action) {
    switch (action.type) {
        case SET_ACTIVE_PAGE: {
            return produce(state, (draft) => {
                draft.activePage = action.payload.pageName;
            });
        }
        default:
            return state;
    }
}
