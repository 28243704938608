import {useSelector} from "react-redux";
import {CatalogGridView} from "./CatalogGridView";
import FAQ from "./FAQ";
import React from "react";
import {ltr} from "../i18n";
import Contact from "./Contact";
import Grants from "./Grants";
import {
    Route,Routes
} from "react-router-dom";
import CookiesAndPrivacy from "./CookiesAndPrivacy";
import Contributors from "./Contributors";



export const AlmostARealRouter = () => {
    return <Routes>
        <Route exact path="/" element={ <CatalogGridView ltr={ltr()}/>} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/faq" element={<FAQ ltr={ltr()} />} />
        <Route exact path="/grants" element={<Grants />} />
        <Route exact path="/privacy" element={<CookiesAndPrivacy />} />
        <Route exact path="/contributors" element={<Contributors />} />
        <Route path="*" element={<CatalogGridView ltr={ltr()} />} />
    </Routes>



};
