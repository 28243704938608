import React from "react";
import {useState} from "react";


const FadeIn = ({children, order = 0, fadeTime, travel=20, className, ...rest}) => {
    const delay = 40; //ms
    const delayThis = delay * order / 1000;
    const [opacity, setOpacity] = useState(0);
    const [translation, setTranslation] = useState(travel);
    setTimeout(() => {
            setOpacity(1);
            setTranslation(0)
        },
        delayThis * 1000
    );
    return (

        <div style={{
            transition: "opacity " + fadeTime + "ms ease 0s, transform " + fadeTime + "ms ease 0s",
            transitionProperty: "opacity, transform",
            transitionDuration: "" + fadeTime + "ms, " + fadeTime + "ms",
            transitionTimingFunction: "ease, ease",
            transitionDelay: delayThis + "s, " + delayThis + "s",
            transform: "translateY("+translation+"px)",
            opacity: opacity
        }}
             className={className}
             {...rest}
        >
            {children}
        </div>

    );
};

export default FadeIn;
